import { useNavigate } from "react-router-dom";
import OrderList from "../components/CustomerSupport/orders/OrdersList";
import { useEffect, useState } from "react";
import { getAccessToken, isTokenValid } from "../util/auth";
import { useSelector, useDispatch } from "react-redux";
import {
  domainActions,
  statusActions,
  selectedDateFromActions,
  selectedDateToActions,
  selectedOrderNumberActions,
  selectedEmailActions,
  selectedTrackingNumberActions,
  selectedOrderSearchTypeActions,
} from "../store";
import Dropdown from "../components/CustomerSupport/Dropdown";
import { URL } from "../api/orders";
import { useQuery } from "@tanstack/react-query";
import { formatDateForInput } from "../util/formatting";
import Shipment from "../components/CustomerSupport/orders/modals/Shipment";
import useApi from "../hooks/use-api";

function CustomerSupportHomePage() {
  const navigate = useNavigate();
  const { sendRequest } = useApi();
  const maxDate = new Date().toISOString().slice(0, 10);
  const [shipmentModal, setShipmentModal] = useState({
    openShipmentModal: false,
    selectedShipmentOrderNumber: "",
  });
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [filterInformation, setFilterInformation] = useState({
    filterByEmail: "",
    filterByBuyerFirstName: "",
    filterByBuyerLastName: "",
    filterByOrderNumber: "",
    filterByOrderId: "",
    filterByBookName: "",
  });

  const {
    filterByEmail,
    filterByBuyerFirstName,
    filterByBuyerLastName,
    filterByOrderNumber,
    filterByOrderId,
    filterByBookName,
  } = filterInformation;

  function filterInformationHandler(event, filterType) {
    switch (filterType) {
      case "email":
        setFilterInformation((prevValue) => {
          return { ...prevValue, filterByEmail: event.target.value };
        });
        break;
      case "buyerFirstName":
        setFilterInformation((prevValue) => {
          return { ...prevValue, filterByBuyerFirstName: event.target.value };
        });
        break;
      case "buyerLastName":
        setFilterInformation((prevValue) => {
          return { ...prevValue, filterByBuyerLastName: event.target.value };
        });
        break;
      case "orderNumber":
        setFilterInformation((prevValue) => {
          return { ...prevValue, filterByOrderNumber: event.target.value };
        });
        break;
      case "orderId":
        setFilterInformation((prevValue) => {
          return { ...prevValue, filterByOrderId: event.target.value };
        });
        break;
      case "bookName":
        setFilterInformation((prevValue) => {
          return { ...prevValue, filterByBookName: event.target.value };
        });
        break;
      default:
        break;
    }

    console.log(
      "Filter information value: " + JSON.stringify(filterInformation)
    );
  }

  function clearFilterFields() {
    setFilterInformation({
      filterByEmail: "",
      filterByBuyerFirstName: "",
      filterByBuyerLastName: "",
      filterByOrderNumber: "",
      filterByOrderId: "",
      filterByBookName: "",
    });
  }

  const { selectedShipmentOrderNumber } = shipmentModal;

  const domainValue = useSelector((state) => state.domain.value);
  const statusValue = useSelector((state) => state.status.value);
  const selectedOrderNumber = useSelector(
    (state) => state.selectedOrderNumber.value
  );
  const selectedEmail = useSelector((state) => state.selectedEmail.value);
  const selectedTrackingNumber = useSelector(
    (state) => state.selectedTrackingNumber.value
  );
  const selectedDateFromValue = useSelector(
    (state) => state.selectedDateFrom.value
  );
  const selectedDateToValue = useSelector(
    (state) => state.selectedDateTo.value
  );

  const selectedOrderSearchType = useSelector(
    (state) => state.selectedOrderSearchType.value
  );
  const dispatch = useDispatch();

  function domainChangeHandler(value) {
    dispatch(domainActions.set(value));
  }

  function statusChangeHandler(value) {
    dispatch(statusActions.set(value));
  }

  function selectedOrderNumberHandler(event) {
    dispatch(selectedOrderNumberActions.set(event.target.value));
  }

  function selectedEmailHandler(event) {
    dispatch(selectedEmailActions.set(event.target.value));
  }

  function selectedTrackingNumberHandler(event) {
    dispatch(selectedTrackingNumberActions.set(event.target.value));
  }

  function selectedDateFromChangeHandler(event) {
    const formattedDate = new Date(event.target.value).toLocaleDateString(
      "en-US"
    );

    dispatch(selectedDateFromActions.set(formattedDate));
  }

  function selectedDateToChangeHandler(event) {
    console.log(event.target.value);

    const formattedDate = new Date(event.target.value).toLocaleDateString(
      "en-US"
    );

    dispatch(selectedDateToActions.set(formattedDate));
  }

  function openShipmentHandler(orderNumber) {
    setShipmentModal({
      openShipmentModal: true,
      selectedShipmentOrderNumber: orderNumber,
    });
  }

  function closeShipmentHandler() {
    setShipmentModal({
      openShipmentModal: false,
      selectedShipmentOrderNumber: "",
    });
  }

  const {
    data: ordersByOrderNumber,
    refetch: refetchOrdersByOrderNumber,
    isFetching: isFetchingOrdersByOrderNumber,
    isFetched: isFetchedOrdersByOrderNumber,
  } = useQuery({
    queryKey: ["ordersByOrderNumber"],
    queryFn: async () => {
      const apiCallConfig = {
        url: `${URL}/customerSupport/getOrdersByOrderNumber`,
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
        params: {
          orderNumber: selectedOrderNumber,
        },
      };

      dispatch(selectedOrderSearchTypeActions.set("order number"));
      clearFilterFields();

      const data = await sendRequest(apiCallConfig);

      if (data.apiError) {
        dispatch(selectedOrderSearchTypeActions.set(""));
      }

      dispatch(domainActions.set("Sve domene"));
      dispatch(statusActions.set("Svi statusi"));
      dispatch(
        selectedDateFromActions.set(new Date().toLocaleDateString("en-US"))
      );
      dispatch(
        selectedDateToActions.set(new Date().toLocaleDateString("en-US"))
      );
      dispatch(selectedEmailActions.set(""));
      dispatch(selectedTrackingNumberActions.set(""));

      return data;
    },
    enabled: false,
  });

  const {
    data: ordersByEmail,
    refetch: refetchOrdersByEmail,
    isFetching: isFetchingOrdersByEmail,
    isFetched: isFetchedOrdersByEmail,
  } = useQuery({
    queryKey: ["ordersByEmail"],
    queryFn: async () => {
      const apiCallConfig = {
        url: `${URL}/customerSupport/getOrdersByEmail`,
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
        params: {
          email: selectedEmail.trim(),
        },
      };

      dispatch(selectedOrderSearchTypeActions.set("email"));

      const data = await sendRequest(apiCallConfig);
      clearFilterFields();

      if (data.apiError) {
        dispatch(selectedOrderSearchTypeActions.set(""));
      }

      dispatch(domainActions.set("Sve domene"));
      dispatch(statusActions.set("Svi statusi"));
      dispatch(
        selectedDateFromActions.set(new Date().toLocaleDateString("en-US"))
      );
      dispatch(
        selectedDateToActions.set(new Date().toLocaleDateString("en-US"))
      );
      dispatch(selectedOrderNumberActions.set(""));
      dispatch(selectedTrackingNumberActions.set(""));

      return data;
    },
    enabled: false,
  });

  const {
    data: ordersByTrackingNumber,
    refetch: refetchOrdersByTrackingNumber,
    isFetching: isFetchingOrdersByTrackingNumber,
    isFetched: isFetchedOrdersByTrackingNumber,
  } = useQuery({
    queryKey: ["ordersByTrackingNumber"],
    queryFn: async () => {
      const apiCallConfig = {
        url: `${URL}/customerSupport/getOrdersByTrackingNumber`,
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
        params: {
          trackingNumber: selectedTrackingNumber,
        },
      };

      dispatch(selectedOrderSearchTypeActions.set("tracking number"));
      clearFilterFields();

      const data = await sendRequest(apiCallConfig);

      if (data.apiError) {
        dispatch(selectedOrderSearchTypeActions.set(""));
      }

      dispatch(domainActions.set("Sve domene"));
      dispatch(statusActions.set("Svi statusi"));
      dispatch(
        selectedDateFromActions.set(new Date().toLocaleDateString("en-US"))
      );
      dispatch(
        selectedDateToActions.set(new Date().toLocaleDateString("en-US"))
      );
      dispatch(selectedEmailActions.set(""));
      dispatch(selectedOrderNumberActions.set(""));

      return data;
    },
    enabled: false,
  });

  const {
    data: ordersByDate,
    refetch: refetchOrdersByDate,
    isFetching: isFetchingOrdersByDate,
    isFetched: isFetchedOrdersByDate,
  } = useQuery({
    queryKey: ["ordersByDate"],
    queryFn: async () => {
      const apiCallConfig = {
        url: `${URL}/customerSupport/getOrdersByDate`,
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
        params: {
          status: statusValue,
          domain: domainValue,
          dateFrom: selectedDateFromValue,
          dateTo: selectedDateToValue,
        },
      };

      dispatch(selectedOrderSearchTypeActions.set("date"));
      clearFilterFields();

      const data = await sendRequest(apiCallConfig);

      if (data.apiError) {
        dispatch(selectedOrderSearchTypeActions.set(""));
      }

      dispatch(selectedOrderNumberActions.set(""));
      dispatch(selectedEmailActions.set(""));
      dispatch(selectedTrackingNumberActions.set(""));

      return data;
    },
    enabled: false,
  });

  const {
    data: initData,
    refetch: refetchInitData,
    isFetching: isFetchingInitData,
    isFetched: isFetchedInitData,
  } = useQuery({
    queryKey: ["ordersOptionsInitData"],
    queryFn: async () => {
      const apiCallConfig = {
        url: `${URL}/customerSupport/ordersOptionsInitData`,
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
      };

      return sendRequest(apiCallConfig);
    },
    enabled: false,
  });

  useEffect(() => {
    if (!isTokenValid()) {
      dispatch(selectedOrderSearchTypeActions.set(""));
      navigate("/auth");
    } else {
      refetchInitData();
    }
  }, [navigate, dispatch, refetchInitData]);

  useEffect(() => {
    switch (selectedOrderSearchType) {
      case "date":
        setFilteredOrders(
          ordersByDate?.filter(
            (s) =>
              s.email.toLowerCase().includes(filterByEmail.toLowerCase()) &&
              s.buyer_first_name.toLowerCase().includes(filterByBuyerFirstName.toLowerCase()) &&
              s.buyer_last_name.toLowerCase().includes(filterByBuyerLastName.toLowerCase()) &&
              s.orderNumber.includes(filterByOrderNumber) &&
              s.id.includes(filterByOrderId) &&
              s.itemList.some(
                (book) =>
                  book.productType === "Book" &&
                  book.productName.toLowerCase().includes(filterByBookName.toLowerCase())
              )
          )
        );
        break;
      case "order number":
        setFilteredOrders(
          ordersByOrderNumber?.filter(
            (s) =>
              s.email.toLowerCase().includes(filterByEmail.toLowerCase()) &&
              s.buyer_first_name.toLowerCase().includes(filterByBuyerFirstName.toLowerCase()) &&
              s.buyer_last_name.toLowerCase().includes(filterByBuyerLastName.toLowerCase()) &&
              s.orderNumber.includes(filterByOrderNumber) &&
              s.id.includes(filterByOrderId) &&
              s.itemList.some(
                (book) =>
                  book.productType === "Book" &&
                  book.productName.toLowerCase().includes(filterByBookName.toLowerCase())
              )
          )
        );
        break;
      case "email":
        setFilteredOrders(
          ordersByEmail?.filter(
            (s) =>
              s.email.includes(filterByEmail.toLowerCase()) &&
              s.buyer_first_name.includes(filterByBuyerFirstName.toLowerCase()) &&
              s.buyer_last_name.includes(filterByBuyerLastName.toLowerCase()) &&
              s.orderNumber.includes(filterByOrderNumber) &&
              s.id.includes(filterByOrderId) &&
              s.itemList.some(
                (book) =>
                  book.productType === "Book" &&
                  book.productName.includes(filterByBookName.toLowerCase())
              )
          )
        );
        break;
      case "tracking number":
        setFilteredOrders(
          ordersByTrackingNumber?.filter(
            (s) =>
              s.email.includes(filterByEmail.toLowerCase()) &&
              s.buyer_first_name.includes(filterByBuyerFirstName.toLowerCase()) &&
              s.buyer_last_name.includes(filterByBuyerLastName.toLowerCase()) &&
              s.orderNumber.includes(filterByOrderNumber) &&
              s.id.includes(filterByOrderId) &&
              s.itemList.some(
                (book) =>
                  book.productType === "Book" &&
                  book.productName.includes(filterByBookName.toLowerCase())
              )
          )
        );
        break;
      default:
        break;
    }
  }, [
    ordersByDate,
    ordersByOrderNumber,
    ordersByEmail,
    ordersByTrackingNumber,
    filterByEmail,
    filterByBuyerFirstName,
    filterByBuyerLastName,
    filterByOrderNumber,
    filterByOrderId,
    filterByBookName,
    selectedOrderSearchType,
  ]);

  const isDataLoading =
    isFetchingOrdersByDate ||
    isFetchingOrdersByOrderNumber ||
    isFetchingOrdersByEmail ||
    isFetchingOrdersByTrackingNumber;

  return (
    <div className="container-fluid">
      <div className="row justify-content-md-center">
        {/* <div className="col-1"></div> */}

        <div className="col-md-3 offset-lg-1 border">
          <div className="row">
            {isFetchingInitData && (
              <>
                <div className="row-md">
                  <div className="form-label mx-1 py my-1 mt-1">
                    <label>Odaberi domenu:</label>
                  </div>
                  <div className="col">
                    <Dropdown
                      title="Domene"
                      value={domainValue}
                      handler={domainChangeHandler}
                      options={["Sve domene"]}
                    ></Dropdown>
                  </div>
                </div>

                <div className="row-md">
                  <div className="col">
                    <label className="form-label mx-1 py my-1 mt-1">
                      Odaberi status:
                    </label>
                  </div>
                  <div className="col">
                    <Dropdown
                      title="Statusi"
                      value={statusValue}
                      handler={statusChangeHandler}
                      options={["Svi statusi"]}
                    ></Dropdown>
                  </div>
                </div>
              </>
            )}

            {!isFetchingInitData &&
              isFetchedInitData &&
              initData &&
              !initData.apiError && (
                <>
                  <div className="row-md">
                    <div className="form-label mx-1 py my-1 mt-1">
                      <label>Odaberi domenu:</label>
                    </div>
                    <div className="col">
                      <Dropdown
                        title="Domene"
                        value={domainValue}
                        handler={domainChangeHandler}
                        options={initData.domains}
                      ></Dropdown>
                    </div>
                  </div>

                  <div className="row-md">
                    <div className="col">
                      <label className="form-label mx-1 py my-1 mt-1">
                        Odaberi status:
                      </label>
                    </div>
                    <div className="col">
                      <Dropdown
                        title="Statusi"
                        value={statusValue}
                        handler={statusChangeHandler}
                        options={initData.statuses}
                      ></Dropdown>
                    </div>
                  </div>
                </>
              )}

            <div className="row">
              <div className="col">
                <div className="row-md">
                  <div className="col">
                    <label className="form-label mx-1 my-1 mt-2">Od:</label>
                    <input
                      className="form-control-sm p-2 mx-1 my-1 mt-1"
                      type="date"
                      pattern="MM/dd/yyyy"
                      value={formatDateForInput(selectedDateFromValue)}
                      onChange={selectedDateFromChangeHandler}
                      max={
                        selectedDateFromValue !== undefined
                          ? formatDateForInput(selectedDateToValue)
                          : maxDate
                      }
                    ></input>
                  </div>

                  <div className="col">
                    <label className="form-label mx-1 py my-1 mt-2">Do:</label>
                    <input
                      className="form-control-sm p-2 mx-1 py my-1 mt-1"
                      type="date"
                      pattern="MM/dd/yyyy"
                      value={formatDateForInput(selectedDateToValue)}
                      onChange={selectedDateToChangeHandler}
                      max={maxDate}
                      min={
                        selectedDateFromValue !== undefined
                          ? formatDateForInput(selectedDateFromValue)
                          : ""
                      }
                    ></input>
                  </div>

                  <div className="col">
                    <button
                      className="btn btn-primary mx-1 my-2"
                      disabled={isDataLoading}
                      onClick={() => refetchOrdersByDate()}
                    >
                      Dohvati
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 gx-lg-3 border">
          <div className="row-md text-md-center">
            <label className="form-label mx-1 py my-1 mt-1">
              Unesi informaciju po kojoj želiš filtrirati
            </label>
          </div>

          <div className="row-md text-md-center">
            <input
              type="text"
              placeholder="Email"
              value={filterByEmail}
              onChange={(event) => filterInformationHandler(event, "email")}
              className="form-control-sm p-2 mx-1 py my-1 mt-1"
            ></input>
          </div>

          <div className="row-md text-md-center">
            <input
              type="text"
              placeholder="Ime naručitelja"
              value={filterByBuyerFirstName}
              onChange={(event) =>
                filterInformationHandler(event, "buyerFirstName")
              }
              className="form-control-sm p-2 mx-1 py my-1 mt-1"
            ></input>
          </div>

          <div className="row-md text-md-center">
            <input
              type="text"
              placeholder="Prezime naručitelja"
              value={filterByBuyerLastName}
              onChange={(event) =>
                filterInformationHandler(event, "buyerLastName")
              }
              className="form-control-sm p-2 mx-1 py my-1 mt-1"
            ></input>
          </div>

          <div className="row-md text-md-center">
            <input
              type="text"
              placeholder="Broj narudžbe"
              value={filterByOrderNumber}
              onChange={(event) =>
                filterInformationHandler(event, "orderNumber")
              }
              className="form-control-sm p-2 mx-1 py my-1 mt-1"
            ></input>
          </div>

          <div className="row-md text-md-center">
            <input
              type="text"
              placeholder="ID narudžbe"
              value={filterByOrderId}
              onChange={(event) => filterInformationHandler(event, "orderId")}
              className="form-control-sm p-2 mx-1 py my-1 mt-1"
            ></input>
          </div>

          <div className="row-md text-md-center">
            <input
              type="text"
              placeholder="Po nazivu knjige"
              value={filterByBookName}
              onChange={(event) => filterInformationHandler(event, "bookName")}
              className="form-control-sm p-2 mx-1 py my-1 mt-1"
            ></input>
          </div>
        </div>

        <div className="col-md-3 gx-lg-3 border">
          <div className="row-md">
            <label className="form-label mx-1 py my-1 mt-1">
              Unesi broj narudžbe:
            </label>
          </div>
          <div className="row-md">
            <input
              type="text"
              value={selectedOrderNumber}
              onChange={selectedOrderNumberHandler}
              className="form-control-sm p-2 mx-1 py my-1 mt-1"
            ></input>
            <button
              className="btn btn-primary p-2 mx-1 py my-2 mt-1"
              disabled={isDataLoading}
              onClick={() => refetchOrdersByOrderNumber()}
            >
              Dohvati
            </button>
          </div>

          <div className="row-md">
            <label className="form-label mx-1 py my-1 mt-1">
              Unesi e-mail:
            </label>
          </div>

          <div className="row-md">
            <input
              type="text"
              value={selectedEmail}
              onChange={selectedEmailHandler}
              className="form-control-sm p-2 mx-1 py my-1 mt-1"
            ></input>
            <button
              className="btn btn-primary p-2 mx-1 py my-2 mt-1"
              disabled={isDataLoading}
              onClick={() => refetchOrdersByEmail()}
            >
              Dohvati
            </button>
          </div>

          <div className="row-md">
            <label className="form-label mx-1 py my-1 mt-1">
              Unesi tracking broj:
            </label>
          </div>

          <div className="row-md">
            <input
              type="text"
              value={selectedTrackingNumber}
              onChange={selectedTrackingNumberHandler}
              className="form-control-sm p-2 mx-1 py my-1 mt-1"
            ></input>
            <button
              className="btn btn-primary p-2 mx-1 py my-2 mt-1"
              disabled={isDataLoading}
              onClick={() => refetchOrdersByTrackingNumber()}
            >
              Dohvati
            </button>
          </div>
        </div>

        {/* <div className="col-1"></div> */}
      </div>

      {isDataLoading && (
        <div className="vh-50 d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {/* {!isDataLoading && selectedOrderSearchType !== "" &&
        (!ordersByOrderNumber || ordersByOrderNumber.length === 0) &&
        (!ordersByEmail || ordersByEmail.length === 0) &&
        (!ordersByTrackingNumber || ordersByTrackingNumber.length === 0) &&
        (!ordersByDate || ordersByDate.length === 0) && <p>No orders</p>} */}

      {/* {!isDataLoading && selectedOrderSearchType === "" && (
        <p>Ovdje će biti prikazane narudžbe kada se učitaju</p>
      )} */}

      {selectedOrderSearchType === "order number" &&
        ordersByOrderNumber?.length !== 0 &&
        !isFetchingOrdersByOrderNumber &&
        isFetchedOrdersByOrderNumber && (
          <OrderList
            orders={filteredOrders ? filteredOrders : ordersByOrderNumber}
            openShipmentHandler={openShipmentHandler}
          />
        )}

      {selectedOrderSearchType === "email" &&
        ordersByEmail?.length !== 0 &&
        !isFetchingOrdersByEmail &&
        isFetchedOrdersByEmail && (
          <OrderList
            orders={filteredOrders ? filteredOrders : ordersByEmail}
            openShipmentHandler={openShipmentHandler}
          />
        )}

      {selectedOrderSearchType === "tracking number" &&
        !isFetchingOrdersByTrackingNumber &&
        ordersByTrackingNumber?.length !== 0 &&
        isFetchedOrdersByTrackingNumber && (
          <OrderList
            orders={filteredOrders ? filteredOrders : ordersByTrackingNumber}
            openShipmentHandler={openShipmentHandler}
          />
        )}

      {selectedOrderSearchType === "date" &&
        ordersByDate?.length !== 0 &&
        !isFetchingOrdersByDate &&
        isFetchedOrdersByDate && (
          <OrderList
            orders={filteredOrders ? filteredOrders : ordersByDate}
            openShipmentHandler={openShipmentHandler}
          />
        )}

      {selectedShipmentOrderNumber !== "" && (
        <Shipment
          orderNumber={selectedShipmentOrderNumber}
          onClose={closeShipmentHandler}
        />
      )}
    </div>
  );
}

export default CustomerSupportHomePage;
