import ModalWrapper from "./ModalWrapper";

function DialogModal({ onClose, onConfirm }) {
  return (
    <ModalWrapper title="Info">
      <div className="modal-body">
        <p>
          Ova narudžba je već poslana! Klikni 'Da' ako želiš da se prethodna
          promjena spremi u arhivu kako bi nova promjena podataka bila
          napravljena!
        </p>
      </div>
      <hr />
      <div className="modal-footer">
        <button className="btn btn-primary my-2" onClick={onConfirm}>
          Da
        </button>
        <button className="btn btn-primary my-2 mx-2" onClick={onClose}>
          Ne
        </button>
      </div>
    </ModalWrapper>
  );
}

export default DialogModal;
