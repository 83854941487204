import { NavLink, useNavigate, useRouteLoaderData } from "react-router-dom";

import { useState } from "react";

const Navigation = () => {
  const token = useRouteLoaderData("root");
  const navigate = useNavigate();
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const navLinkClickHandler = () => {
    if (isNavbarOpen) {
      setIsNavbarOpen(false);
    }
  };

  function logoutHandler() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessTokenExpiration");
    navLinkClickHandler();

    return navigate("/auth");
  }

  // const showNavBarItems = (navBarOpened) => {
  //   return (
  //     <div className={navBarOpened ? "d-flex justify-content-center" : ""}>
  //       <div
  //         className={`collapse navbar-collapse ${navBarOpened ? "show" : ""}`}
  //       >
  //         <div className={!navBarOpened ? "d-flex justify-content-center" : ""}>
  //           <ul className="navbar-nav ">
  //             <li className="nav-item">
  //               <NavLink
  //                 className="nav-link"
  //                 aria-current="page"
  //                 onClick={navLinkClickHandler}
  //                 to="/"
  //                 end
  //               >
  //                 Home
  //               </NavLink>
  //             </li>
  //             <li className="nav-item">
  //               <NavLink
  //                 className="nav-link"
  //                 onClick={navLinkClickHandler}
  //                 to="/customerSupport/orders"
  //                 end
  //               >
  //                 Customer support
  //               </NavLink>
  //             </li>
  //           </ul>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <nav className="navbar navbar-expand-sm bg-body-secondary justify-content-between m-1">
      <NavLink className="navbar-brand" onClick={navLinkClickHandler} to="/">
        <img
          src="/img/tvornica-snova.png"
          width={75}
          height={75}
          alt="Tvornica snova logo"
          className="img-fluid mx-2"
        ></img>
      </NavLink>

      <button
        className="navbar-toggler"
        type="button"
        onClick={toggleNavbar}
        // aria-expanded="false"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      {!isNavbarOpen && (
        <div className="d-flex">
          <div
            className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`}
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  aria-current="page"
                  onClick={navLinkClickHandler}
                  to="/"
                  end
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  onClick={navLinkClickHandler}
                  to="/customerSupport/orders"
                  end
                >
                  Customer support
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      )}

      {/* {!isNavbarOpen && showNavBarItems(isNavbarOpen)} */}

      {token && (
        <NavLink className="nav-item mx-2">
          <button className="button btn-primary" onClick={logoutHandler}>
            Logout
          </button>
        </NavLink>
      )}

      {/* {isNavbarOpen && showNavBarItems(isNavbarOpen)} */}

      {isNavbarOpen && (
        <div
          className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`}
        >
          <div className="d-flex justify-content-center">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  aria-current="page"
                  onClick={navLinkClickHandler}
                  to="/"
                  end
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  onClick={navLinkClickHandler}
                  to="/customerSupport/orders"
                  end
                >
                  Customer support
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigation;
