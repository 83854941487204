
function PageContent({ title, children }) {
  return (
    <div className="d-fluid justify-content-center m-3">
      <h1 className="text-center"><strong>{title}</strong></h1>
      {children}
    </div>
  );
}

export default PageContent;
