import React, { useEffect, useState } from "react";
import PagingTableFooter from "../../../UI/PagingTableFooter";

function MergedOrdersTable({
  orders,
  itemsPerPage = 8,
  selectedOrders,
  handleRowClick,
}) {
  const [currentPage, setCurrentPage] = useState(1);

  let totalPages = Math.ceil(orders.length / itemsPerPage);
  if (totalPages > 15) {
    totalPages = 15;
    itemsPerPage = Math.ceil(orders.length / totalPages);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = orders.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    setCurrentPage(1);
  }, [totalPages]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="d-xl-flex container-fluid justify-content-md-center">
      <div className="table-responsive">
        <table className="table table-sm table-hover border">
          <thead className="table-light">
            <tr>
              <th>Spojene narudžbe</th>
              <th>Ime</th>
              <th>Prezime</th>
              <th>Mjesto</th>
              <th>Zip</th>
              <th>Ulica</th>
              <th>Kućni broj</th>
              <th>Kontakt broj</th>
              <th>resources/Email_poslana_narudžba</th>
              <th>Pouzeće</th>
              <th>Država</th>
              <th>Vrsta dostave</th>
              <th>Dostavna služba</th>
              <th>Podatak promijenjen kod slanja</th>
              <th>Napomena</th>
              <th>Razlog izmjene</th>
              <th>Korisnik</th>
              <th>Zapis kreiran</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((order, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(order)}
                className={selectedOrders.includes(order) ? "table-primary" : ""}
              >
                <td>{order.mergeOrderNumbers}</td>
                <td>{order.name}</td>
                <td>{order.lastName}</td>
                <td>{order.place}</td>
                <td>{order.zip}</td>
                <td>{order.street}</td>
                <td>{order.streetNumber}</td>
                <td>{order.phone}</td>
                <td>{order.email}</td>
                <td>{order.pickUpAmount}</td>
                <td>{order.state}</td>
                <td>{order.typeOfDelivery}</td>
                <td>{order.deliveryCompany}</td>
                <td>{order.changed_data}</td>
                <td>{order.remark}</td>
                <td>{order.reasonForChange}</td>
                <td>{order.userID}</td>
                <td>{order.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <PagingTableFooter
          handlePageChange={handlePageChange}
          totalPages={totalPages}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
}

export default MergedOrdersTable;
