import { useEffect, useState } from "react";
import { Form, redirect, useActionData, useNavigate } from "react-router-dom";
import { getAccessToken, isTokenValid } from "../util/auth";
import Coupons from "../components/CustomerSupport/couponCheck/Coupons";
import { getCouponData } from "../api/orders";

function CouponCheckPage() {
  const [couponCode, setCouponCode] = useState("");
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
  const actionData = useActionData();
  const navigate = useNavigate();

  useEffect(() => {
    if (isTokenValid()) {
      const timer = setTimeout(() => {
        if (couponCode.length > 1) {
          setConfirmButtonDisabled(false);
        } else {
          setConfirmButtonDisabled(true);
        }
      }, 150);

      return () => clearTimeout(timer);
    } else {
      navigate("/auth");
    }
  }, [couponCode, navigate]);

  function couponCodeChangeHandler(event) {
    setCouponCode(event.target.value);
  }

  return (
    <div className="container-fuid d-md-flex justify-content-center">
      <div className="form-group">
        <Form method="post">
          <div className="form-input">
            <label className="form-label mx-4 py my-1 mt-1">
              Unesi kupon kod za provjeru:
            </label>
            <input
              className="form-control-sm p-2 mx-4 py my-2 mt-1"
              value={couponCode}
              onChange={couponCodeChangeHandler}
              name="couponCode"
              type="text"
            ></input>
            <button
              className="btn btn-primary p-2 mx-4 py my-2 mt-1"
              disabled={confirmButtonDisabled}
            >
              Dohvati podatke
            </button>
          </div>
          {actionData && <Coupons coupons={actionData} />}
        </Form>
      </div>
    </div>
  );
}

export async function action({ request }) {
    const formData = await request.formData();

    const responseData = await getCouponData(
      getAccessToken(),
      formData.get("couponCode")
    );

    if (responseData.apiError && responseData.status === 401) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessTokenExpiration");
  
      return redirect("/auth");
    } else {
      return responseData;
    }
}

export default CouponCheckPage;
