import { useRouteError } from "react-router-dom";
import Navigation from "../components/Navigation";
import PageContent from "../components/PageContent";

function ErrorPage() {
    const error = useRouteError();

    let title = 'An error occured!'
    let message = 'Something went wrong'

    if(error.status === 500){
        message = error.data.message;
    }

    if(error.status === 400){
        title = 'Not found.';
        message = 'Could not find resource or page'
    }

    return (<>
        <Navigation />
        <PageContent title={title}>
            <p>{message}</p>
        </PageContent>
    </>)
}

export default ErrorPage;