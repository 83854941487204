import { configureStore, createSlice } from "@reduxjs/toolkit";

const initialDomainState = { value: "" };

const domainSlice = createSlice({
  name: "domain",
  initialState: initialDomainState,
  reducers: {
    set(state, action) {
      state.value = action.payload;
    },
  },
});

const initialStatusState = { value: "" };

const statusSlice = createSlice({
  name: "status",
  initialState: initialStatusState,
  reducers: {
    set(state, action) {
      state.value = action.payload;
    },
  },
});

const initialSelectedOrderSearchTypeState = { value: "" };

const selectedOrderSearchTypeSlice = createSlice({
  name: "selectedOrderSearchType",
  initialState: initialSelectedOrderSearchTypeState,
  reducers: {
    set(state, action) {
      state.value = action.payload;
    },
  },
});

const initialBookSearchOptionsState = { value: "" };

const selectedBookSearchOptionSlice = createSlice({
  name: "selectedSearchBookOptions",
  initialState: initialBookSearchOptionsState,
  reducers: {
    set(state, action) {
      state.value = action.payload;
    },
  },
});

const initialOrderNumberState = { value: "" };

const selectedOrderNumberSlice = createSlice({
  name: "orderNumber",
  initialState: initialOrderNumberState,
  reducers: {
    set(state, action) {
      state.value = action.payload;
    },
  },
});

const initialEmailState = { value: "" };

const selectedEmailSlice = createSlice({
  name: "email",
  initialState: initialEmailState,
  reducers: {
    set(state, action) {
      state.value = action.payload;
    },
  },
});

const initialTrackingNumberState = { value: "" };

const selectedTrackingNumberSlice = createSlice({
  name: "selectedTrackingNumber",
  initialState: initialTrackingNumberState,
  reducers: {
    set(state, action) {
      state.value = action.payload;
    },
  },
});

const dateFrom = new Date().toLocaleDateString("en-US");
const initialDateFromState = { value: dateFrom };

const selectedDateFromSlice = createSlice({
  name: "selectedDateFrom",
  initialState: initialDateFromState,
  reducers: {
    set(state, action) {
      state.value = action.payload;
    },
  },
});

const dateTo = new Date().toLocaleDateString("en-US");
const initialDateToState = { value: dateTo };

const selectedDateToSlice = createSlice({
  name: "selectedDateTo",
  initialState: initialDateToState,
  reducers: {
    set(state, action) {
      state.value = action.payload;
    },
  },
});

const store = configureStore({
  reducer: {
    domain: domainSlice.reducer,
    status: statusSlice.reducer,
    selectedDateFrom: selectedDateFromSlice.reducer,
    selectedDateTo: selectedDateToSlice.reducer,
    selectedOrderNumber: selectedOrderNumberSlice.reducer,
    selectedEmail: selectedEmailSlice.reducer,
    selectedTrackingNumber: selectedTrackingNumberSlice.reducer,
    selectedBookSearchOption: selectedBookSearchOptionSlice.reducer,
    selectedOrderSearchType: selectedOrderSearchTypeSlice.reducer,
  },
});

export const domainActions = domainSlice.actions;
export const statusActions = statusSlice.actions;
export const selectedDateFromActions = selectedDateFromSlice.actions;
export const selectedDateToActions = selectedDateToSlice.actions;
export const selectedOrderNumberActions = selectedOrderNumberSlice.actions;
export const selectedEmailActions = selectedEmailSlice.actions;
export const selectedTrackingNumberActions =
  selectedTrackingNumberSlice.actions;
export const selectedBookSearchOptionActions =
  selectedBookSearchOptionSlice.actions;
  export const selectedOrderSearchTypeActions = selectedOrderSearchTypeSlice.actions;

export default store;
