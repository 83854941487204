import { Suspense, useEffect, useState } from "react";
import {
  Await,
  defer,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import { getAccessToken, isTokenValid } from "../util/auth";
import { getMergeOrdersData } from "../api/orders";
import OrderMergeForm from "../components/CustomerSupport/orders/OrderMergeForm";
import ResponseModal from "../components/CustomerSupport/orders/modals/ResponseModal";

function OrderMergePage() {
  const { data, orderNumber } = useLoaderData();
  const navigate = useNavigate();
  const [responseMessage, setResponseMessage] = useState();
  const [responseError, setResponseError] = useState();
  const [openResponseModal, setOpenResponseModal] = useState(false);

  useEffect(() => {
    if (!isTokenValid()) {
      return navigate("/auth");
    }
  }, [navigate]);

  function closeResponseModalHandler() {
    document.body.style.overflow = "auto";
    setOpenResponseModal(false);

    const redirectUrl = "/customerSupport/order/" + orderNumber;
    console.log(redirectUrl);

    return navigate(redirectUrl);
  }

  function formSubmitHandler(response) {
    if (response.apiError) {
      setResponseError(response.apiError);
    } else {
      setResponseMessage(response);
    }
    document.body.style.overflow = "hidden";
    setOpenResponseModal(true);
  }

  return (
    <>
      <Suspense
        fallback={
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        }
      >
        <Await resolve={data}>
          {(loadedData) => (
            <OrderMergeForm data={loadedData} onSubmit={formSubmitHandler} />
          )}
        </Await>
      </Suspense>
      {openResponseModal && (
        <ResponseModal
          message={responseMessage}
          error={responseError}
          onClose={closeResponseModalHandler}
        />
      )}
    </>
  );
}

export async function loader({ params }) {
  const responseData = await getMergeOrdersData(getAccessToken(), params.orderNumber);
  if (responseData.apiError && responseData.status === 401) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessTokenExpiration");

    return redirect("/auth");
  } else {
    return defer({
      data: responseData,
      orderNumber: params.orderNumber,
    });
  }
}

export default OrderMergePage;
