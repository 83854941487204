import { useEffect, useState } from "react";
import OrderListElement from "./OrdersListElement";
import PagingTableFooter from "../../../UI/PagingTableFooter";

function OrderList({orders, openShipmentHandler, itemsPerPage=7}) {
  const [currentPage, setCurrentPage] = useState(1);

  let totalPages = Math.ceil(orders.length / itemsPerPage);
  if(totalPages > 10) {
    totalPages = 10;
    itemsPerPage = Math.ceil(orders.length/totalPages);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = orders.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    setCurrentPage(1)
  }, [orders])

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="table-responsive-lg m-2">
      <table className="table table-striped table-bordered table-hover">
        <thead>
          <tr>
            <th>Status</th>
            <th>Broj narudžbe</th>
            <th>Ažurirano</th>
            <th>Naručitelj</th>
            <th>Primatelj</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((order, index) => (
            <OrderListElement key={index} order={order} openShipmentHandler={openShipmentHandler}/>
          ))}
        </tbody>
      </table>
      <PagingTableFooter handlePageChange={handlePageChange}  totalPages={totalPages} currentPage={currentPage} />
    </div>
  );
}

export default OrderList;
