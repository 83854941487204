import { Await, defer, redirect, useLoaderData } from "react-router-dom";
import { getBookData } from "../api/orders";
import { getAccessToken } from "../util/auth";
import { Suspense } from "react";

function OrderViewBookDataPage() {
  const { bookData } = useLoaderData();

  return (
    <div className="container-flex">
      <div className="d-flex justify-content-center m-2 border-bottom">
        <h1>Pregled podataka o personaliziranoj knjizi</h1>
      </div>

      <Suspense
        fallback={
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        }
      >
        <Await resolve={bookData}>
          {(loadedData) => (
            <div className="row m-2">
              <div className="col-lg-4 text-center">
                <h3 className="text-warning">
                  Fotografija djeteta na stražnjoj stranici korica
                </h3>
                <img
                  src={loadedData.childPhotoUrl}
                  alt="Child"
                  width={400}
                  height={400}
                  class="img-fluid rounded"
                />
              </div>

              <div className="col-lg-4">
                <div className="d-flex justify-content-center">
                  <div className="row">
                    <h3 className="text-center text-warning">
                      Tekstualni podaci
                    </h3>

                    <table className="table table-sm table-bordered">
                      <tbody>
                        {Object.entries(loadedData.textualData).map(
                          ([key, value]) => (
                            <tr>
                              <td>
                                <strong>{`${key}: `}</strong>
                              </td>
                              <td>{value}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="row">
                    <h3 className="text-center text-warning">
                      Podaci o avatarima
                    </h3>

                    <table className="table table-sm table-bordered">
                      <tbody>
                        {loadedData.avatarsData.map((data) =>
                          Object.entries(data).map(([key, value]) =>
                            key.includes("Avatar") ? (
                              <tr>
                                <td>
                                  <u>
                                    <strong>{`${key}: `}</strong>
                                  </u>
                                </td>
                                <td>{value}</td>
                              </tr>
                            ) : (
                              <tr>
                                <td>
                                  <strong>{`${key}: `}</strong>
                                </td>
                                <td>{value}</td>
                              </tr>
                            )
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <h3 className="text-center text-warning">Posveta</h3>
                <div className="d-block justify-content-center">
                  <div className="row m-2 border">
                    {loadedData.dedicationList.map((line) => (
                      <p>{line}</p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Await>
      </Suspense>
    </div>
  );
}

export async function loader({ params }) {
  const responseData = await getBookData(getAccessToken(), params.bookCode);

  if (responseData.apiError && responseData.status === 401) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessTokenExpiration");

    return redirect("/auth");
  } else {
    return defer({
      bookData: responseData,
    });
  }
}

export default OrderViewBookDataPage;
