import { useState } from "react";
import { Link } from "react-router-dom";
import AddExtraItem from "./modals/AddExtraItem";
import RemoveItem from "./modals/RemoveItem";
import ResponseModal from "./modals/ResponseModal";

function OrderItem({ item, productDomain, orderNumber }) {
  const isBook = item.productType === "Book";
  const [extraProductsModal, setExtraProductsModal] = useState({
    itemType: "",
    showModal: false,
  });
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [isActionDone, setIsActionDone] = useState({
    message: "",
    isDone: false,
  });
  const { showModal, itemType } = extraProductsModal;
  const { message, isDone } = isActionDone;

  function addExtraItemHandler() {
    document.body.style.overflow = "hidden";
    setExtraProductsModal({ itemType: "extra_products", showModal: true });
  }

  function addWrapHandler() {
    document.body.style.overflow = "hidden";
    setExtraProductsModal({ itemType: "wraps", showModal: true });
  }

  function closeExtraProductsHandler() {
    document.body.style.overflow = "auto";
    setExtraProductsModal({ itemType: "", showModal: false });
  }

  function confirmExtraProductsHandler(message) {
    document.body.style.overflow = "auto";
    setExtraProductsModal({ itemType: "", showModal: false });
    setIsActionDone({ message: message, isDone: true });
  }

  function removeItemHandler() {
    document.body.style.overflow = "hidden";
    setShowRemoveModal(true);
  }

  function closeRemoveItemHandler() {
    document.body.style.overflow = "auto";
    setShowRemoveModal(false);
  }

  function confirmRemoveItemHandler(message) {
    document.body.style.overflow = "auto";
    setShowRemoveModal(false);
    setIsActionDone({ message: message, isDone: true });
  }

  function closeResponseHandler() {
    document.body.style.overflow = "auto";
    setIsActionDone({ message: "", isDone: false });
    window.location.reload();
  }

  return (
    <>
      <tr>
        <td>{item.productName}</td>
        <td>{item.item_Code}</td>
        <td>{item.coupon_code}</td>
        <td>
          {isBook && (
            <Link
              to={`/customerSupportApp/bookData/${item.item_Code}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn btn-primary mx-1">
                Provjeri unesene podatke
              </button>
            </Link>
          )}
          {isBook && (
            <button
              className="btn btn-success mx-1"
              onClick={addExtraItemHandler}
            >
              Dodaj extra proizvod
            </button>
          )}
          {isBook && (
            <button className="btn btn-success mx-1" onClick={addWrapHandler}>
              Dodaj ukrasno pakiranje
            </button>
          )}
          {!isBook && (
            <button className="btn btn-danger mx-1" onClick={removeItemHandler}>
              Ukloni proizvod
            </button>
          )}
        </td>
      </tr>
      {isBook && showModal && (
        <AddExtraItem
          itemType={itemType}
          orderItemId={item.order_item_id}
          productId={item.productID}
          productDomain={productDomain}
          orderNumber={orderNumber}
          onClose={closeExtraProductsHandler}
          onConfirm={confirmExtraProductsHandler}
        />
      )}
      {!isBook && showRemoveModal && (
        <RemoveItem
          itemCode={item.item_Code}
          orderNumber={orderNumber}
          orderItemId={item.order_item_id}
          onClose={closeRemoveItemHandler}
          onConfirm={confirmRemoveItemHandler}
        />
      )}
      {isDone && (
        <ResponseModal
          message={message}
          onClose={closeResponseHandler}
        ></ResponseModal>
      )}
    </>
  );
}

export default OrderItem;
