import { Link } from "react-router-dom";

function OrderActionBar({
  order,
  onShowRemoveFromPrintConfirmation,
  onShowCancelOrderConfirmation,
}) {
  const canDownloadInvoice =
    order.invoice.url !== "" && order.invoice.url !== "null";

  const canRemoveFromPrint =
    order.status === "Zaprimljeno" ||
    order.status === "accepted" ||
    order.status === "Priprema za tisak spremna" ||
    order.status === "pdf_generated";

  const canCancelOrder =
    order.status === "Zaprimljeno" ||
    order.status === "accepted" ||
    order.status === "Priprema za tisak se generira" ||
    order.status === "generating_pdf" ||
    order.status === "Priprema za tisak spremna" ||
    order.status === "pdf_generated";

  function removeFromPrintHandler() {
    onShowRemoveFromPrintConfirmation();
  }

  function cancelOrderHandler() {
    onShowCancelOrderConfirmation();
  }

  return (
    <div className="text-center">
      {canDownloadInvoice && (
                <Link to={order.invoice.url} target="_blank">
                  <button className="btn btn-primary mx-1 my-1" >Preuzmi račun</button>
                </Link>
              )}
              {canRemoveFromPrint && (
                <button className="btn btn-primary mx-1 my-1"  onClick={removeFromPrintHandler}>Makni iz tiska</button>
              )}
              {canCancelOrder && (
                <button className="btn btn-primary mx-1 my-1" onClick={cancelOrderHandler}>Otkaži narudžbu</button>
              )}
              <Link to={`/customerSupport/editOrderData/${order.orderNumber}`}>
                <button className="btn btn-primary mx-1 my-1" >Promjena podataka</button>
              </Link>
              <Link to={`/customerSupport/mergeOrderData/${order.orderNumber}`}>
                <button className="btn btn-primary mx-1 my-1" >Spajanje narudžbi</button>
              </Link>
    </div>
  );
}

export default OrderActionBar;
