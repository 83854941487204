import { useLoaderData, useNavigate } from "react-router-dom";
import OrderEditForm from "../components/CustomerSupport/orders/OrderEditForm";
import { URL } from "../api/orders";
import { getAccessToken } from "../util/auth";
import { useEffect, useState } from "react";
import DialogModal from "../components/CustomerSupport/orders/modals/DialogModal";
import AlertModal from "../components/CustomerSupport/orders/modals/AlertModal";
import useApi from "../hooks/use-api";
import ResponseModal from "../components/CustomerSupport/orders/modals/ResponseModal";

function OrderEditPage() {
  const orderNumber = useLoaderData();
  const [data, setData] = useState();
  const [showData, setShowData] = useState(false);
  const [openDialogModal, setOpenDialogModal] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState();
  const [responseError, setResponseError] = useState();
  const { sendRequest, isLoading } = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const apiCallConfig = {
        url: `${URL}/customerSupport/getOrderEditData`,
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
        params: {
          orderNumber,
        },
      };
      const responseData = await sendRequest(apiCallConfig);

      if (responseData.orderChanged && responseData.orderEdited) {
        document.body.style.overflow = "hidden";
        setOpenDialogModal(true);
      } else if (!responseData.orderChanged && !responseData.orderEdited) {
        setData(responseData.data);
        setShowData(true);
      } else if (responseData.orderChanged && !responseData.orderEdited) {
        document.body.style.overflow = "hidden";
        setOpenAlertModal(true);
        setData(responseData.data);
      }
    };

    getData();
  }, [orderNumber, sendRequest]);

  function dialogModalCloseHandler() {
    document.body.style.overflow = "auto";
    setOpenDialogModal(false);
    setData(async () => {
      const apiCallConfig = {
        url: `${URL}/customerSupport/getUserSelectedDataForEdit`,
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
        params: {
          orderNumber,
          editOption: false,
        },
      };
      const responseData = await sendRequest(apiCallConfig);
      
      console.log("Order for edit data: " + JSON.stringify(responseData))

      setData(responseData);
      setShowData(true);
    });
  }

  function dialogModalConfirmHandler() {
    document.body.style.overflow = "auto";
    setOpenDialogModal(false);
    setData(async () => {
      const apiCallConfig = {
        url: `${URL}/customerSupport/getUserSelectedDataForEdit`,
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
        params: {
          orderNumber,
          editOption: true,
        },
      };
      const responseData = await sendRequest(apiCallConfig);

      setData(responseData);
      setShowData(true);
    });
  }

  function closeAlertModalHandler() {
    document.body.style.overflow = "auto";
    setOpenAlertModal(false);
    setShowData(true);
  }

  function closeResponseModalHandler() {
    document.body.style.overflow = "auto";
    setOpenResponseModal(false);

    return navigate("/customerSupport/order/" + orderNumber);
  }

  function formSubmitHandler(response) {
    if (response.apiError) {
      setResponseError(response.apiError);
    } else {
      setResponseMessage(response);
    }
    setOpenResponseModal(true);
  }

  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {!isLoading && (
        <>
          {openDialogModal && (
            <DialogModal
              onClose={dialogModalCloseHandler}
              onConfirm={dialogModalConfirmHandler}
            />
          )}
          {openAlertModal && (
            <AlertModal
              message="Ovaj barkod je već dodan. Prikazat će se podaci koji su odabrani u zadnjoj izmjeni!"
              onClose={closeAlertModalHandler}
            />
          )}
          {showData && data && (
            <OrderEditForm data={data} onSubmit={formSubmitHandler} />
          )}
          {openResponseModal && (
            <ResponseModal
              message={responseMessage}
              error={responseError}
              onClose={closeResponseModalHandler}
            />
          )}
        </>
      )}
    </>
  );
}

export function loader({ params }) {
  return params.orderNumber;
}

export default OrderEditPage;
