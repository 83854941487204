import { Await, defer, redirect, useLoaderData, useNavigate } from "react-router-dom";
import { getOrderByOrderNumber } from "../api/orders";
import Order from "../components/CustomerSupport/orders/Order";
import { getAccessToken, isTokenValid } from "../util/auth";
import { Suspense, useEffect } from "react";

function OrderDetailPage() {
  const { order } = useLoaderData();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isTokenValid()) {
      return navigate("/auth");
    }
  }, [navigate]);

  return (
    <Suspense fallback={(
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )}>
      <Await resolve={order}>
        {(loadedData) => <Order order={loadedData} />}
      </Await>
    </Suspense>
  );
}

export default OrderDetailPage;

export async function loader({ params }) {
  const responseData = await getOrderByOrderNumber(getAccessToken(), params.orderNumber);

  if (responseData.apiError && responseData.status === 401) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessTokenExpiration");

    return redirect("/auth");
  } else { 
    return defer({
      order: responseData
    });
  }
}
