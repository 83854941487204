import { Form, useActionData, useNavigation } from "react-router-dom";

function AuthForm() {
  const data = useActionData();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  return (
    <div className="d-flex justify-content-center">
      <div className="row text-center">
        <div className="col">
          <br />
          <img src="/img/tvornica-snova.png" alt="Less logo" width="300"></img>
          <br />
          <Form method="post" className="form-group">
            <br />

            <h1 className="text-center">Log in</h1>
            {data && data.apiError && (
              <label className="form-label p-2 py my-1 mt-1">
                {data.apiError.message}
              </label>
            )}
            {data && data.message && (
              <label className="form-label p-2 py my-1 mt-1">
                {data.message}
              </label>
            )}
            <div className="row text-start">
              <label className="form-label py my-1 mt-1" htmlFor="email">
                Email
              </label>
            </div>
            <input
              className="form-control py my-2 mt-1"
              id="email"
              type="email"
              name="email"
              required
            />
            <div className="row text-start">
              <label className="form-label py my-1 mt-1" htmlFor="password">
                Lozinka
              </label>
            </div>
            <input
              className="form-control py my-2 mt-1"
              id="password"
              type="password"
              name="password"
              required
            />
            <br />
            <button disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Log in"}
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AuthForm;
