import { useState } from "react";
import Coupon from "./Coupon";
import PagingTableFooter from "../../../UI/PagingTableFooter";

function Coupons({ coupons, itemsPerPage = 15 }) {
  const [currentPage, setCurrentPage] = useState(1);

  let totalPages = Math.ceil(coupons.length / itemsPerPage);
  if (totalPages > 15) {
    totalPages = 15;
    itemsPerPage = Math.ceil(coupons.length / totalPages);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = coupons.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
      <div className="flex-row-md-6">
        {coupons.length === 0 && (
          <div>
            <h3>No coupons found</h3>
          </div>
        )}
        {coupons.length > 0 && (
          <div className="table-responsive">
            <table className="table table-striped border">
              <thead>
                <tr>
                  <th>Broj narudžbe</th>
                  <th>Kupon</th>
                  <th>Zaključan</th>
                  <th>Id narudžbe</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((coupon, index) => (
                  <tr key={index}>
                    <Coupon coupon={coupon} />
                  </tr>
                ))}
              </tbody>
            </table>
            <PagingTableFooter
              handlePageChange={handlePageChange}
              totalPages={totalPages}
              currentPage={currentPage}
            />
          </div>
        )}
      </div>
  );
}

export default Coupons;
