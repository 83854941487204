import { Link } from "react-router-dom";

function OrderListElement({order, openShipmentHandler}) {

  function shipmentButtonHandler(){
    openShipmentHandler(order.orderNumber);
  }

  return (
      <tr>
        <td>{order.status}</td>
        <td>{order.orderNumber}</td>
        <td>{order.updated_at}</td>
        <td>
          {order.buyer_first_name + " " + order.buyer_last_name}
        </td>
        <td>
          {order.shipping_first_name + " " + order.shipping_last_name}
        </td>
        <td>{order.email}</td>
        <td>
          <div><Link to={`/customerSupport/order/${order.orderNumber}`}><button className="btn btn-primary mx-1">Pregled narudžbe</button></Link>
          {order.status === "shipped" && <button className="btn btn-primary mx-1" onClick={shipmentButtonHandler}>Pregled pošiljaka</button>}</div>
        </td>
      </tr>
  );
}

export default OrderListElement;
