import { useEffect, useRef, useState } from "react";

function BookSearchForm({ options, onSubmit }) {
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);

  const [childFirstName, setChildFirstName] = useState("");
  const [childLastName, setChildLastName] = useState("");
  const [childMothersName, setChildMothersName] = useState("");
  const [childFathersName, setChildFathersName] = useState("");
  const [bookName, setBookName] = useState("");
  const formRef = useRef();

  useEffect(() => {
    const timer = setTimeout(() => {
      setConfirmButtonDisabled(childFirstName.trim().length < 1);
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [childFirstName]);

  function childFirstNameHandler(event) {
    setChildFirstName(event.target.value);
  }

  function childLastNameHandler(event) {
    setChildLastName(event.target.value);
  }

  function childMothersNameHandler(event) {
    setChildMothersName(event.target.value);
  }

  function childFathersNameHandler(event) {
    setChildFathersName(event.target.value);
  }

  function selectedBookHandler(event) {
    setBookName(event.target.value);
  }

  async function submitHandler(event) {
    event.preventDefault();
    const data = new FormData(formRef.current);

    const bookSearchData = {
      childFirstName: data.get("childFirstName"),
      childLastName: data.get("childLastName"),
      childMothersName: data.get("childMothersName"),
      childFathersName: data.get("childFathersName"),
      bookName: data.get("bookName"),
    };

    onSubmit(bookSearchData);
  }

  return (
    <form method="post" onSubmit={submitHandler} ref={formRef}>
      <div className="row">
        <div className="col-md">
          <label className="form-label">Unesi ime djeteta:</label>
          <input
            name="childFirstName"
            value={childFirstName}
            onChange={childFirstNameHandler}
            type="text"
            className="form-control"
          ></input>
        </div>
        <div className="col-md">
          <label className="form-label">Unesi prezime djeteta:</label>
          <input
            name="childLastName"
            value={childLastName}
            onChange={childLastNameHandler}
            type="text"
            className="form-control"
          ></input>
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <label className="form-label">Unesi ime majke:</label>
          <input
            name="childMothersName"
            value={childMothersName}
            onChange={childMothersNameHandler}
            type="text"
            className="form-control"
          ></input>
        </div>
        <div className="col-md">
          <label className="form-label">Unesi ime oca:</label>
          <input
            name="childFathersName"
            value={childFathersName}
            onChange={childFathersNameHandler}
            type="text"
            className="form-control"
          ></input>
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <label className="form-label">Odaberi knjigu:</label>
          <select
            title="Book select"
            name="bookName"
            value={bookName}
            onChange={selectedBookHandler}
            className="form-select"
          >
            {options.map((o) => (
              <option id={o} key={o} value={o}>
                {o}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md">
          <br></br>
          <button
            className="btn btn-primary my-2"
            type="submit"
            disabled={confirmButtonDisabled}
          >
            Dohvati podatke
          </button>
        </div>
        <div className="flex-row"></div>
      </div>
    </form>
  );
}

export default BookSearchForm;
