import { redirect, useNavigate } from "react-router-dom";
import AuthForm from "../components/Auth/AuthForm";
import {authenticate } from "../api/orders";
import jwtDecode from "jwt-decode";
import { isTokenValid } from "../util/auth";
import { useEffect } from "react";

function AuthenticationPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (isTokenValid()) {
      return navigate("/");
    }
  }, [navigate]);

  return <AuthForm />;
}

export default AuthenticationPage;

export async function action({ request }) {
  const data = await request.formData();
  const authData = {
    email: data.get("email"),
    password: data.get("password"),
  };

  const resData = await authenticate(authData);

  if (resData.apiError) {
    console.log("Error response data: " + JSON.stringify(resData))
    return resData;
  } 
  localStorage.setItem("accessToken", resData.access_token);
  localStorage.setItem("refreshToken", resData.refresh_token);

  const decodedToken = jwtDecode(resData.access_token);
  console.log("Decoded token: " + JSON.stringify(decodedToken));

  const expiration = new Date();
  // expiration.setMinutes(expiration.getMinutes() + 24*60);
  // expiration.setMinutes(expiration.getMinutes() + 30);
  expiration.setSeconds(expiration.getSeconds() + 30);

  localStorage.setItem("accessTokenExpiration", expiration.toISOString());

  return redirect("/");
}
