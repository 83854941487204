import axios from "axios";

  // export const URL = "https://172.105.70.41:8443/api/v1";
  // export const URL = "http://localhost:7071/api/v1";
  export const URL = "https://pluma-api.less.hr/api/v1";

export function authenticate(authData) {
  const response = axios
    .post(`${URL}/auth/authenticate`, JSON.stringify(authData), {
      headers: {
        "Content-Type": "application/json",
      },
    })  
    .then((res) => res.data)
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        const resData = {};
        resData.apiError = error.response.data.apierror;
        resData.status = error.response.status;
        return resData;
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("Error.request: " + error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    });

  return response;
}

export async function getOrderByOrderNumber(token, orderNumber) {
  console.log("In getOrderbyOrderNumber, " + orderNumber);

  const data = axios
    .get(
      `${URL}/customerSupport/getOrderByOrderNumber?orderNumber=${orderNumber}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then((res) => res.data)
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        const resData = {};
        resData.apiError = error.response.data.apierror;
        resData.status = error.response.status;
        return resData;
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("Error.request: " + error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    });

  console.log("Order data: " + data);

  return data;
}


export async function getBookData(token, bookCode) {
  const data = axios
    .get(`${URL}/customerSupport/bookData?bookCode=${bookCode}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => res.data);

  return data;
}


export async function getMergeOrdersData(token, orderNumber) {
  const data = await axios
    .get(
      `${URL}/customerSupport/getMergeOrdersData?orderNumber=${orderNumber}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then((res) => res.data)
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        const resData = {};
        resData.apiError = error.response.data.apierror;
        resData.status = error.response.status;
        return resData;
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("Error.request: " + error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    });

  console.log("getMergeOrdersData result: " + JSON.stringify(data));

  return data;
}

export async function getCouponData(token, couponCode) {
  const data = axios
    .get(URL + `/customerSupport/getCouponInfo?couponCode=${couponCode}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        const resData = {};
        resData.apiError = error.response.data.apierror;
        resData.status = error.response.status;
        return resData;
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("Error.request: " + error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    });

  return data;
}
