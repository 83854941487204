import ModalWrapper from "./ModalWrapper";

function AlertModal({ message, onClose }) {
  return (
    <ModalWrapper onClose={onClose} title="Info">
      <div className="modal-body">
        <p>{message}</p>
      </div>
      <hr/>
      <div className="modal-footer">
        <button className="btn btn-primary" onClick={onClose}>
          U redu
        </button>
      </div>
    </ModalWrapper>
  );
}

export default AlertModal;
