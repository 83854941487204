import React, { useEffect, useState } from "react";
import PagingTableFooter from "../../../UI/PagingTableFooter";

function ChangedOrdersTable({
  orders,
  itemsPerPage = 8,
  selectedOrders,
  handleRowClick,
}) {
  const [currentPage, setCurrentPage] = useState(1);

  let totalPages = Math.ceil(orders.length / itemsPerPage);
  if (totalPages > 15) {
    totalPages = 15;
    itemsPerPage = Math.ceil(orders.length / totalPages);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = orders.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    setCurrentPage(1);
  }, [totalPages]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="d-xl-flex container-fluid justify-content-md-center">
      <div className="table-responsive">
        <table className="table table-sm table-hover border">
          <thead className="table-light">
            <tr>
              <th>Broj narudžbe reklamacije</th>
              <th>Originalni broj reklamacije</th>
              <th>Pouzeće</th>
              <th>Dostavna služba</th>
              <th>Podatak promijenjen kod slanja</th>
              <th>Zamjena paketa</th>
              <th>Napomena</th>
              <th>Razlog izmjene</th>
              <th>Korisnik</th>
              <th>Zapis kreiran</th>
              <th>ID paketomata</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((order, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(order)}
                className={selectedOrders.includes(order) ? "table-primary" : ""}
              >
                <td>{order.reclamationOrderNum}</td>
                <td>{order.originalOrderNumber}</td>
                <td>{order.pickupAmount}</td>
                <td>{order.deliveryCompany}</td>
                <td>{JSON.stringify(order.changedData)}</td>
                <td>{JSON.stringify(order.exchangePackage)}</td>
                <td>{order.napomena}</td>
                <td>{order.reasonForChange}</td>
                <td>{order.userId}</td>
                <td>{order.createdAt}</td>
                <td>{order.paketomat_newDestinationID}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <PagingTableFooter
          handlePageChange={handlePageChange}
          totalPages={totalPages}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
}

export default ChangedOrdersTable;
