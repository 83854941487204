function OrderInfo({ order }) {
  const totalPrice =
    order.total_price.fractional / order.total_price.subunit_to_unit;

  return (
    <div className="d-flex row justify-content-md-between">
      <div className="table-responsive">
        <div className="col-lg-5">
          <table className="table table-sm table-bordered">
            <thead className="table-light">
              <tr>
                <th></th>
                <th>Podaci o naručitelju</th>
                <th>Podaci o primatelju</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ime i prezime:</td>
                <td>{`${order.buyer_first_name} ${order.buyer_last_name}`}</td>
                <td>{`${order.shipping_first_name} ${order.shipping_last_name}`}</td>
              </tr>
              <tr>
                <td>Adresa:</td>
                <td>{`${order.buyer_street} ${order.buyer_street_number}`}</td>
                <td>{`${order.shipping_street} ${order.shipping_street_number}`}</td>
              </tr>
              <tr>
                <td>Mjesto: </td>
                <td>{`${order.buyer_zip_code} ${order.buyer_city}`}</td>
                <td>{`${order.shipping_zip_code} ${order.shipping_city}`}</td>
              </tr>
              <tr>
                <td>Telefon:</td>
                <td>{order.buyer_phone}</td>
                <td>{order.shipping_phone}</td>
              </tr>
              <tr>
                <td>E-mail:</td>
                <td>{order.email}</td>
                <td>{order.shipping_email}</td>
              </tr>
              <tr>
                <td>Država:</td>
                <td>{order.buyer_country}</td>
                <td>{order.shipping_country_iso}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="table-responsive">
        <div className="col-lg-5">
          <table className="table table-sm table-bordered">
            <thead className="table-light">
              <tr>
                <th>Podaci o narudžbi</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Datum narudžbe:</td>
                <td>{order.purchased_at}</td>
              </tr>
              <tr>
                <td>Datum ažuriranja:</td>
                <td>{order.updated_at}</td>
              </tr>
              <tr>
                <td>Gost kupovina:</td>
                <td>{JSON.stringify(order.bought_as_guest)}</td>
              </tr>
              <tr>
                <td>Način plaćanja:</td>
                <td>{order.payment_type}</td>
              </tr>
              <tr>
                <td>Iznos košarice:</td>
                <td>{totalPrice}</td>
              </tr>
              <tr>
                <td>Kupon kod dostave</td>
                <td>{order.coupon_code}</td>
              </tr>
              <tr>
                <td>Vrsta dostave:</td>
                <td>{order.deliveryOption.delivery_type}</td>
              </tr>
              <tr>
                <td>Domena narudžbe:</td>
                <td>{order.deliveryOption.domain}</td>
              </tr>
              <tr>
                <td>Odabrana dostavna služba:</td>
                <td>{order.deliveryCompanySelector.deliveryCompany}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default OrderInfo;
