import { URL } from "../../../../api/orders";
import { getAccessToken } from "../../../../util/auth";
import ModalWrapper from "./ModalWrapper";
import useApi from "../../../../hooks/use-api";
import { useQuery } from "@tanstack/react-query";
import ShipmentsTable from "../ShipmentsTable";
import { useEffect } from "react";

function Shipment({ orderNumber, onClose }) {
  const { sendRequest } = useApi();

  const { data, isFetching, isFetched, refetch } = useQuery({
    queryKey: ["shipmentData"],
    queryFn: async () => {
      const apiCallConfig = {
        url: `${URL}/customerSupport/getPackages`,
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
        params: {
          orderNumber: orderNumber,
        },
      };
      document.body.style.overflow = "hidden";

      return await sendRequest(apiCallConfig);
    },
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [refetch])

  function onCloseHandler() {
    document.body.style.overflow = "auto";
    onClose();
  }

  return (
    <ModalWrapper
      modalType="modal-shipment"
      title="Podaci o pošiljkama"
      onClose={onCloseHandler}
    >
      {isFetching && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      <div className="modal-body">
        {!isFetching && isFetched && data && !data.apiError && (
          <ShipmentsTable shipments={data} />
        )}
        <hr />
      </div>

      <div className="modal-footer">
        <button onClick={onCloseHandler}>Close</button>
      </div>
    </ModalWrapper>
  );
}

export default Shipment;
