import axios from "axios";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

function useApi() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const sendRequest = useCallback(
    async (config) => {
      setIsLoading(true);

      const response = await axios({
        url: config.url,
        method: config.method ? config.method : "get",
        headers: config.headers ? config.headers : {},
        data: config.data ? JSON.stringify(config.data) : {},
        params: config.params ? config.params : {},
      })
        .then((res) => {
          console.log("Data:" + JSON.stringify(res.data))
          return res.data})
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("Error response data: " + JSON.stringify(error.response.data));
            const resData = {};
            resData.apiError = error.response.data.apierror;
            resData.status = error.response.status;
            return resData;
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("Error.request: " + error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });

      setIsLoading(false);

      if (
        response.status &&
        (response.status === 401 || response.status === 403)
      ) {
        console.group(
          "Token expired or unauthorized access, error is: " +
            JSON.stringify(response.apiError)
        );

        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessTokenExpiration");

        navigate("/auth");
      }

      return response;
    },
    [navigate]
  );

  return {
    sendRequest,
    isLoading,
  };
}

export default useApi;
