import { useEffect, useState } from "react";
import ModalWrapper from "./ModalWrapper";
import { URL } from "../../../../api/orders";
import { getAccessToken } from "../../../../util/auth";
import useApi from "../../../../hooks/use-api";

function AddExtraItem({
  itemType,
  orderItemId,
  productId,
  productDomain,
  orderNumber,
  onClose,
  onConfirm,
}) {
  const [itemSelected, setItemSelected] = useState(false);
  const [itemValue, setItemValue] = useState();
  const [extraItems, setExtraItems] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { sendRequest } = useApi();

  useEffect(() => {
    const getData = async () => {
      const apiCallConfig = {
        url: `${URL}/customerSupport/getExtraItems`,
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
        params: {
          itemType,
          productId,
          productDomain,
        },
      };
      const data = await sendRequest(apiCallConfig);

      setExtraItems(data);
    };

    getData();
  }, [itemType, productId, productDomain, sendRequest]);

  function onChangeHandler(event) {
    setItemSelected(true);
    setItemValue(JSON.parse(event.target.value));
  }

  async function confirmButtonHandler() {
    console.log("Selected item title: " + itemValue.title);

    const apiCallConfig = {
      url: `${URL}/customerSupport/addExtraItems`,
      headers: {
        Authorization: "Bearer " + getAccessToken(),
      },
      params: {
        orderNumber,
        orderItemId,
        itemType,
        extraProductId: itemValue.id,
      },
    };

    setIsDataLoading(true);
    const message = await sendRequest(apiCallConfig);
    setIsDataLoading(false);

    onConfirm(JSON.stringify(message));
  }

  return (
    <ModalWrapper onClose={onClose} title="Dodaj extra proizvod">
      <div className="modal-body">
        {!isDataLoading && (
          <>
            <div>
              <label className="form-label">
                <strong>Broj narudžbe: </strong>
              </label>
              <input
                className="form-control"
                disabled
                value={orderNumber}
              ></input>
            </div>
            <div>
              <label className="form-label">
                <strong>Extra proizvod: </strong>
              </label>
              <select className="form-select" onChange={onChangeHandler}>
                <option value="" selected disabled hidden>
                  Odaberi proizvod
                </option>
                {extraItems.map((item) => (
                  <option
                    id={item.id}
                    key={item.id}
                    value={JSON.stringify(item)}
                  >
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </>
        )}
        {isDataLoading && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <hr />
      </div>
      <div className="modal-footer">
        {!isDataLoading && (
          <>
            <button className="btn btn-primary" onClick={onClose}>
              Close
            </button>
            <button
              className="btn btn-primary my-2 mx-2"
              disabled={!itemSelected}
              onClick={confirmButtonHandler}
            >
              Dodaj proizod
            </button>
          </>
        )}
      </div>
    </ModalWrapper>
  );
}

export default AddExtraItem;
