import { Outlet } from "react-router-dom";
import CustomerSupportNavigation from "../components/CustomerSupport/CustomerSupportNavigation";

function CustomerSupportRootLayout() {
  return (
    <>
      <CustomerSupportNavigation />
      <Outlet />
    </>
  );
}

export default CustomerSupportRootLayout;
