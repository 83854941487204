import React, { useState } from "react";
import PagingTableFooter from "../../../UI/PagingTableFooter";

function ShipmentsTable({ shipments, itemsPerPage = 1 }) {
  const [currentPage, setCurrentPage] = useState(1);

  let totalPages = Math.ceil(shipments.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = shipments.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {currentItems.map((shipment, index) => (
        <div key={index}>
          <h3 className="text-center">Pošiljka broj {Number(index + 1)}:</h3>
          <div className="table-responsive">
            <table className="table table-sm table-striped border ">
              <tbody>
                <tr>
                  <td>
                    <strong>Datum slanja: </strong>
                  </td>
                  <td>{shipment.dateOfSending}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Status paketa: </strong>
                  </td>
                  <td> {shipment.deliveryStatus}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Referenca: </strong>
                  </td>
                  <td>{shipment.reference} </td>
                </tr>
                <tr>
                  <td>
                    <strong>Dostavna služba: </strong>
                  </td>
                  <td> {shipment.deliveryCompany}</td>
                </tr>
                {!(shipment.trackingLink === "") && (
                  <>
                    <tr>
                      <td>
                        <strong>Tracking link: </strong>
                      </td>
                      <td> {shipment.trackingLink} </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Shipment ID: </strong>
                      </td>
                      <td>{shipment.shipmentID} </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Tracking broj: </strong>
                      </td>
                      <td>{shipment.trackingNumber}</td>
                    </tr>
                  </>
                )}
                <tr>
                  <td>
                    <strong>Primatelj: </strong>
                  </td>
                  <td> {shipment.receiverFirstNameLastName}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Adresa: </strong>
                  </td>
                  <td> {shipment.address} </td>
                </tr>
                <tr>
                  <td>
                    <strong>Mjesto i ZIP: </strong>
                  </td>
                  <td> {shipment.placeAndZip} </td>
                </tr>
                <tr>
                  <td>
                    <strong>Država: </strong>
                  </td>
                  <td>{shipment.country} </td>
                </tr>
                <tr>
                  <td>
                    <strong>Kontakt broj: </strong>
                  </td>
                  <td> {shipment.contactNumber}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Iznos pouzeća: </strong>
                  </td>
                  <td>{shipment.deliveryAmount}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}

      <PagingTableFooter
        handlePageChange={handlePageChange}
        totalPages={totalPages}
        currentPage={currentPage}
      />
    </>
  );
}

export default ShipmentsTable;
