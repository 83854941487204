export function formatDateForInput(dateString) {
    // Parse the input date string into a Date object
    const dateObject = new Date(dateString);
  
    // Check if the parsed date is valid
    if (isNaN(dateObject.getTime())) {
      throw new Error('Invalid date input.');
    }
  
    // Get the year, month, and day from the Date object
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(dateObject.getDate()).padStart(2, '0');
  
    // Construct the date string in "YYYY-MM-DD" format
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }