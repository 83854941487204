import { redirect } from "react-router-dom";

export function getAccessTokenExpiration() {
  const storedExpirationDate = localStorage.getItem("accessTokenExpiration");
  const expirationDate = new Date(storedExpirationDate);
  const now = new Date();
  const duration = expirationDate.getTime() - now.getTime();

  return duration;
}

export function getRefreshTokenExpiration() {
  const storedExpirationDate = localStorage.getItem("refreshTokenExpiration");
  const expirationDate = new Date(storedExpirationDate);
  const now = new Date();
  const duration = expirationDate.getTime() - now.getTime();

  return duration;
}

export function getAccessToken() {
  const token = localStorage.getItem("accessToken");

  const tokenDuration = getAccessTokenExpiration();

  if (tokenDuration < 0 || !token) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessTokenExpiration");

    return redirect("/auth");
  } else {
    return token;
  }
}

export function getRefreshToken() {
  const token = localStorage.getItem("refreshToken");

  if (!token) {
    return null;
  }

  const tokenDuration = getRefreshTokenExpiration();

  if (tokenDuration < 0) {
    return redirect("/auth");
  }

  return token;
}

export function refreshTokenLoader() {
  return getRefreshToken();
}

export function accessTokenLoader() {
  return getAccessToken();
}

export function checkAuthLoader() {
  const accessToken = getAccessToken();

  if (!accessToken || accessToken === "EXPIRED") {
    return redirect("/auth");
  }

  return true;
}

export function isTokenValid(){
  const token = localStorage.getItem("accessToken");
  const tokenDuration = getAccessTokenExpiration();

  if (tokenDuration < 0 || !token) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessTokenExpiration");

    return false;
  } else {
    return true;
  }
}
