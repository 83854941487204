import { useEffect, useRef, useState } from "react";
import { getAccessToken } from "../../../util/auth";
import { URL } from "../../../api/orders";
import useApi from "../../../hooks/use-api";

function OrderEditForm({ data, onSubmit }) {
  const [isReclamationCODEditable, setIsReclamationCODEditable] =
    useState(false);
  const [canSaveEditData, setCanSaveEditData] = useState(false);
  const [changeReason, setChangeReason] = useState("");
  const [zipCode, setZipCode] = useState(data.reclamationOrderPONumber);
  const [selectedDeliveryCompany, setSelectedDeliveryCompany] = useState(
    data.selectedDeliveryCompany
  );
  const [paketomatData, setPaketomatData] = useState( data.paketomati.length !== 0 && data.paketomati[0].code !== "" ? data.paketomati : []);

  const [selectedPaketomat, setSelectedPaketomat] = useState();

  const formRef = useRef();
  const { sendRequest } = useApi();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (changeReason.length >= 15) {
        setCanSaveEditData(true);
      } else {
        setCanSaveEditData(false);
      }
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [changeReason]);

  function codCheckBoxHandler() {
    setIsReclamationCODEditable(!isReclamationCODEditable);
  }

  function changeReasonHandler(event) {
    setChangeReason(event.target.value);
  }

  function zipCodeChangeHandler(event) {
    setZipCode(event.target.value);
  }

  function deliveryCompanyChangeHandler(event) {
    setSelectedDeliveryCompany(JSON.parse(event.target.value));
  }

  async function paketomatButtonHandler(event) {
    event.preventDefault();
    if (
      selectedDeliveryCompany.deliverySelectorOption !== "" &&
      zipCode !== ""
    ) {
      // const data = await getPaketomatData(
      //   getAccessToken(),
      //   zipCode,
      //   selectedDeliveryCompany.deliverySelectorOption
      // );

      const apiCallConfig = {
        url: `${URL}/customerSupport/getPaketomatData`,
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
        params: {
          zipCode,
          deliveryCompanyName: selectedDeliveryCompany.deliverySelectorOption,
        },
      };
      const data = await sendRequest(apiCallConfig);

      setPaketomatData(data);
    }
  }

  function paketomatDataHandler(event) {
    setSelectedPaketomat(JSON.parse(event.target.value));
  }

  async function submitHandler(event) {
    event.preventDefault();

    const data = new FormData(formRef.current);
    
    const changedOrderData = {
      reclamationOrderNumber: data.get("reclamationOrderNumber"),
      reclamationOrderFirstName: data.get("reclamationOrderFirstName"),
      reclamationOrderLastName: data.get("reclamationOrderLastName"),
      reclamationOrderStreetName: data.get("reclamationOrderStreetName"),
      reclamationOrderHouseNumber: data.get("reclamationOrderHouseNumber"),
      reclamationOrderPlaceName: data.get("reclamationOrderPlaceName"),
      reclamationOrderPONumber: data.get("reclamationOrderPONumber"),
      reclamationOrderPhoneNumber: data.get("reclamationOrderPhoneNumber"),
      reclamationOrderEmail: data.get("reclamationOrderEmail"),
      reclamationOrderCOD: parseFloat(data.get("reclamationOrderCOD")).toFixed(2),
      reclamationOrderCodCheckBox: data.get("reclamationOrderCodCheckBox"),
      reclamationOrderCountryName: data.get("reclamationOrderCountryName"),
      selectedDeliveryCompany: JSON.parse(data.get("selectedDeliveryCompany")),
      reclamationOrderExchangePacket: data.get("reclamationOrderExchangePacket"),
      reclamationOrderRemark: data.get("reclamationOrderRemark"),
      selectedPaketomat: JSON.parse(data.get("selectedPaketomat")),
      reclamationOrderChangeReason: data.get("reclamationOrderChangeReason"),
      originalOrderNumber: data.get("originalOrderNumber"),
    };

    console.log("Data for sending: " + JSON.stringify(changedOrderData));

  // const response = await saveOrderEditData(getAccessToken(), changedOrderData);

  const apiCallConfig = {
    method: "post",
    url: `${URL}/customerSupport/saveOrderEditData`,
    headers: {
      Authorization: "Bearer " + getAccessToken(),
      "Content-Type": "application/json"
    },
    data: changedOrderData,
  }

  const message = await sendRequest(apiCallConfig);

  console.log("Response from saveOrderEditData is: " + JSON.stringify(message));

  onSubmit(message);
  }

  return (
    <>
      <div className="jumbotron text-center">
        <h1>Promjena podataka narudžbe</h1>
      </div>

      <div className="d-flex-md m-2">
        <form method="post" ref={formRef} onSubmit={submitHandler}>
          <div className="d-flex row">
            <div className="col-7 col-md-2">
              <h3 className="text-center">Napomena za dostavu</h3>
              <textarea
                className="form-control text-center"
                rows="4"
                name="reclamationOrderRemark"
              ></textarea>
            </div>

            <div className="d-flex col-7 col-md-4 justify-content-md-center">

              <table>
                <tbody>
                  <tr>
                    <td>
                      <h3 className="text-center">Reklamacija</h3>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Broj narudžbe reklamacije</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="reclamationOrderNumber"
                        defaultValue={data.reclamationOrderNumber}
                      ></input>
                    </td>
                    {/* <td>
                      <button id="reclamationOrderNumberButton">
                        Dohvati narudžbu
                      </button>
                    </td> */}
                  </tr>
                  <tr>
                    <td>
                      <label>Ime</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="reclamationOrderFirstName"
                        defaultValue={data.reclamationOrderFirstName}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Prezime</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="reclamationOrderLastName"
                        defaultValue={data.reclamationOrderLastName}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Ulica</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="reclamationOrderStreetName"
                        defaultValue={data.reclamationOrderStreetName}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Kućni broj</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="reclamationOrderHouseNumber"
                        defaultValue={data.reclamationOrderHouseNumber}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Mjesto</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="reclamationOrderPlaceName"
                        defaultValue={data.reclamationOrderPlaceName}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Poštanski broj</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="reclamationOrderPONumber"
                        value={zipCode}
                        onChange={zipCodeChangeHandler}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Telefon</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="reclamationOrderPhoneNumber"
                        defaultValue={data.reclamationOrderPhoneNumber}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Email</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="reclamationOrderEmail"
                        defaultValue={data.reclamationOrderEmail}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Pouzeće</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        disabled={!isReclamationCODEditable}
                        name="reclamationOrderCOD"
                        defaultValue={data.reclamationOrderCOD}
                      ></input>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="reclamationOrderCodCheckBox"
                        onChange={codCheckBoxHandler}
                      ></input>
                      Izmjena pouzeća
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Država</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="reclamationOrderCountryName"
                        defaultValue={data.reclamationOrderCountryName}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Dostavna služba</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        className="form-select"
                        name="selectedDeliveryCompany"
                        value={JSON.stringify(selectedDeliveryCompany)}
                        onChange={deliveryCompanyChangeHandler}
                      >
                        {data.deliveryCompanies.map((comp, index) => (
                          <option
                            id={comp.showDeliveryCompName}
                            key={comp.showDeliveryCompName + "_" + index}
                            value={JSON.stringify(comp)}
                          >
                            {comp.showDeliveryCompName}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="reclamationOrderExchangePacket"
                      ></input>
                      Exchange paket
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Paketomat lokacija</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        className="form-select"
                        name="selectedPaketomat"
                        value={JSON.stringify(selectedPaketomat)}
                        onChange={paketomatDataHandler}
                      >
                        {paketomatData.map((paketomat) => (
                          <option
                            key={paketomat.id}
                            value={JSON.stringify(paketomat)}
                          >
                            {paketomat.code +
                              "," +
                              paketomat.name +
                              "," +
                              paketomat.address}
                          </option>
                        ))}
                      </select>
                      <button onClick={paketomatButtonHandler}>
                        Dohvati listu paketomata
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>

            <div className="d-flex col-7 col-md-4 justify-content-md-center">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <h3 className="text-center">Original</h3>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Originalni broj narudžbe</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="originalOrderNumber"
                        defaultValue={data.originalOrderNumber}
                        readOnly
                      ></input>
                    </td>
                    {/* <td>
                      <button id="originalOrderNumberButton">
                        Dohvati narudžbu
                      </button>
                    </td> */}
                  </tr>
                  <tr>
                    <td>
                      <label>Ime</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={data.originalOrderFirstName}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Prezime</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={data.originalOrderLastName}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Ulica</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={data.originalOrderStreetName}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Kućni broj</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={data.originalOrderHouseNumber}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Mjesto</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={data.originalOrderPlaceName}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Poštanski broj</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={data.originalOrderPONumber}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Telefon</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={data.originalOrderPhoneNumber}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Email</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={data.originalOrderEmail}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Pouzeće</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={data.originalOrderCOD}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Država</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={data.originalOrderCountryName}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Način plaćanja</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={data.originalOrderPaymentType}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-7 col-md-2">
              <h3 className="text-center">Razlog promjene podataka</h3>
              <textarea
                className="form-control text-center"
                name="reclamationOrderChangeReason"
                value={changeReason}
                onChange={changeReasonHandler}
              ></textarea>
              <button
                disabled={!canSaveEditData}
                className="btn btn-primary my-2"
                title="Potreban je unos od minimalno 15 znakova kao razlog promjene podataka!"
              >
                Spremi promjene
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default OrderEditForm;
