import classes from "./ModalWrapper.module.css";
import ReactDOM from "react-dom";

function BackDrop(props) {
  return <div className={classes.backdrop} onClick={props.onClose}></div>;
}

function ModalOverlay(props) {
  return (
    <div
      className={props?.modalType ? classes[props.modalType] : classes.modal}
    >
      <div className={classes.content}>{props.children}</div>
    </div>
  );
}

const portalElement = document.getElementById("overlays");

function ModalWrapper(props) {
  return (
    <>
      {ReactDOM.createPortal(
        <BackDrop onClose={props.onClose} />,
        portalElement
      )}
      <div className="modal" tabIndex="-1" role="dialog">
        {ReactDOM.createPortal(
          <ModalOverlay modalType={props.modalType}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="modal-title me-auto"><h4>{props?.title}</h4></div>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={props.onClose}
                    ></button>
                </div>
                <hr></hr>
                {props.children}
              </div>
            </div>
          </ModalOverlay>,
          portalElement
        )}
      </div>
    </>
  );
}

export default ModalWrapper;
