import ModalWrapper from "./ModalWrapper";

function ResponseModal({ message, error, onClose }) {
  return (
    <ModalWrapper onClose={onClose}>
      <div className="modal-body">
        {message && (
          <div className="text-center">
            <p>
              <strong>{message}</strong>
            </p>
          </div>
        )}
        {error && (
          <div className="text-center">
            <p>Opis greške:</p>
            <p>{error.message}</p>
          </div>
        )}
      </div>
      <hr/>
      <div className="modal-footer">
        <button className="btn btn-primary" onClick={onClose}>
          U redu
        </button>
      </div>
    </ModalWrapper>
  );
}

export default ResponseModal;
