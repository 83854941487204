import React from "react";
import classes from "./PagingTableFooter.module.css"

function PagingTableFooter({handlePageChange, totalPages, currentPage}){
    return(<div className="text-center">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={classes.button}
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={index + 1 === currentPage ? classes["button active"] : classes["button"]}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={classes.button}
        >
          Next
        </button>
      </div>
      )
}

export default PagingTableFooter;

