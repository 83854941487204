import OrderItem from "./OrderItem";

function OrderItems({ order }) {
  return (
    <div  className="table-responsive">
    <table className="table table-bordered">
      <thead className="table-light">
        <tr>
          <th>Book name</th>
          <th>Book barcode</th>
          <th>Coupon code</th>
          <th className="text-center">Actions</th>
        </tr>
      </thead>
      <tbody>
        {(order.itemList).map((item) => <OrderItem key={item.order_item_id} item={item} productDomain={order.deliveryOption.domain} orderNumber={order.orderNumber}/>)}
      </tbody>
    </table>
    </div>
  );
}

export default OrderItems;
