import { useState } from "react";
import PagingTableFooter from "../../../UI/PagingTableFooter";

function BookDataItems({ items, itemsPerPage = 10 }) {
  const [currentPage, setCurrentPage] = useState(1);

  let totalPages = Math.ceil(items.length / itemsPerPage);
  if (totalPages > 20) {
    totalPages = 20;
    itemsPerPage = Math.ceil(items.length / totalPages);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div>
        <table className="table table-striped border">
          <thead>
            <tr>
              <th>Broj narudžbe</th>
              <th>Datum rođenja</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index}>
                <td>{item.orderNumber}</td>
                <td>{item.dateOfBirth}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <PagingTableFooter
          handlePageChange={handlePageChange}
          totalPages={totalPages}
          currentPage={currentPage}
        />
      </div>
    </>
  );
}

export default BookDataItems;
