import { useEffect, useRef, useState } from "react";
import { getAccessToken } from "../../../util/auth";
import useApi from "../../../hooks/use-api";
import { URL } from "../../../api/orders";

function OrderMergeForm({ data, onSubmit }) {
  const [canSaveMergeData, setCanSaveMergeData] = useState(false);
  const [mergeOrderChangeReason, setMergeOrderChangeReason] = useState("");
  const [originalOrderNumber, setOriginalOrderNumber] = useState(
    data.originalOrderNumber
  );
  const { sendRequest } = useApi();
  const formRef = useRef();

  const [originalOrderData, setOriginalOrderData] = useState({
    originalOrderFirstName: data.originalOrderFirstName,
    originalOrderLastName: data.originalOrderLastName,
    originalOrderStreetName: data.originalOrderStreetName,
    originalOrderHouseNumber: data.originalOrderHouseNumber,
    originalOrderPlaceName: data.originalOrderPlaceName,
    originalOrderPONumber: data.originalOrderPONumber,
    originalOrderPhoneNumber: data.originalOrderPhoneNumber,
    originalOrderEmail: data.originalOrderEmail,
    originalOrderCOD: data.originalOrderCOD,
    originalOrderCountryName: data.originalOrderCountryName,
    originalOrderPaymentType: data.originalOrderPaymentType,
    originalOrderDeliveryCompany: data.originalOrderDeliveryCompany,
    originalOrderDeliveryCompanies: data.originalOrderDeliveryCompanies,
  });

  const {
    originalOrderFirstName,
    originalOrderLastName,
    originalOrderStreetName,
    originalOrderHouseNumber,
    originalOrderPlaceName,
    originalOrderPONumber,
    originalOrderPhoneNumber,
    originalOrderEmail,
    originalOrderCOD,
    originalOrderCountryName,
    originalOrderPaymentType,
    originalOrderDeliveryCompany,
    originalOrderDeliveryCompanies,
  } = originalOrderData;

  const [mergeOrderNumber, setMergeOrderNumber] = useState("");
  const [mergeOrderFirstName, setMergeOrderFirstName] = useState("");
  const [mergeOrderLastName, setMergeOrderLastName] = useState("");
  const [mergeOrderStreetName, setMergeOrderStreetName] = useState("");
  const [mergeOrderHouseNumber, setMergeOrderHouseNumber] = useState("");
  const [mergeOrderPlaceName, setMergeOrderPlaceName] = useState("");
  const [mergeOrderPONumber, setMergeOrderPONumber] = useState("");
  const [mergeOrderPhoneNumber, setMergeOrderPhoneNumber] = useState("");
  const [mergeOrderEmail, setMergeOrderEmail] = useState("");
  const [mergeOrderCOD, setMergeOrderCOD] = useState(0);
  const [mergeOrderCountryName, setMergeOrderCountryName] = useState("");
  const [selectedDeliveryCompany, setSelectedDeliveryCompany] = useState({});

  const [isMergeOrderCODEditable, setIsMergeOrderCODEditable] = useState(false);

  function originalOrderChangeHandler(event) {
    setOriginalOrderNumber(event.target.value);
  }

  function mergeOrderNumberChangeHandler(event) {
    setMergeOrderNumber(event.target.value);
  }

  function mergeOrderFirstNameChangeHandler(event) {
    setMergeOrderFirstName(event.target.value);
  }

  function mergeOrderLastNameChangeHandler(event) {
    setMergeOrderLastName(event.target.value);
  }

  function mergeOrderStreetNameChangeHandler(event) {
    setMergeOrderStreetName(event.target.value);
  }

  function mergeOrderHouseNumberChangeHandler(event) {
    setMergeOrderHouseNumber(event.target.value);
  }

  function mergeOrderPlaceNameChangeHandler(event) {
    setMergeOrderPlaceName(event.target.value);
  }

  function mergeOrderPONumberChangeHandler(event) {
    setMergeOrderPONumber(event.target.value);
  }

  function mergeOrderPhoneNumberChangeHandler(event) {
    setMergeOrderPhoneNumber(event.target.value);
  }

  function mergeOrderEmailChangeHandler(event) {
    setMergeOrderEmail(event.target.value);
  }

  function mergeOrderCODChangeHandler(event) {
    setMergeOrderCOD(event.target.value);
  }

  function mergeOrderCountryNameChangeHandler(event) {
    setMergeOrderCountryName(event.target.value);
  }

  function codCheckBoxHandler() {
    setIsMergeOrderCODEditable(!isMergeOrderCODEditable);
  }

  function deliveryCompanyChangeHandler(event) {
    setSelectedDeliveryCompany(JSON.parse(event.target.value));
  }

  function mergeReasonHandler(event) {
    setMergeOrderChangeReason(event.target.value);
  }

  function mergeOrdersData(event) {
    event.preventDefault();
    setMergeOrderNumber((prevValue) => {
      if (prevValue === "") {
        return originalOrderNumber;
      } else {
        return prevValue + "-" + originalOrderNumber;
      }
    });
    setMergeOrderFirstName(originalOrderFirstName);
    setMergeOrderLastName(originalOrderLastName);
    setMergeOrderStreetName(originalOrderStreetName);
    setMergeOrderHouseNumber(originalOrderHouseNumber);
    setMergeOrderPlaceName(originalOrderPlaceName);
    setMergeOrderPONumber(originalOrderPONumber);
    setMergeOrderPhoneNumber(originalOrderPhoneNumber);
    setMergeOrderEmail(originalOrderEmail);
    setMergeOrderCOD((prevValue) => {
      console.log("PrevValue: " + prevValue)
      if (prevValue === 0) {
        return originalOrderCOD;
      } else {
        console.log("PrevValue raw: " + prevValue);
        console.log(
          "PrevValue formatted: " + Number.parseFloat(prevValue).toFixed(2)
        );
        console.log(
          "OriginalCOD raw: " + Number.parseFloat(originalOrderCOD).toFixed(2)
        );
        console.log(
          "OriginalCOD formatted: " +
            Number.parseFloat(originalOrderCOD).toFixed(2)
        );

        const sum = Number(prevValue) + Number(originalOrderCOD);

        return sum.toLocaleString();
      }
    });
    setMergeOrderCountryName(originalOrderCountryName);
    setSelectedDeliveryCompany(originalOrderDeliveryCompany);
  }

  async function addOrderHandler(event) {
    event.preventDefault();
    if (originalOrderNumber.length !== 13) {
      window.alert("Neispravan broj narudžbe!");
    } else if (originalOrderNumber.length === 0) {
      window.alert("Unesi broj narudžbe za spajanje!");
    } else {
      const apiCallConfig = {
        url: `${URL}/customerSupport/getMergeOrdersData`,
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
        params: {
          orderNumber: originalOrderNumber,
        },
      };
      const orderData = await sendRequest(apiCallConfig);

      setOriginalOrderData({
        originalOrderNumber: orderData.originalOrder,
        originalOrderFirstName: orderData.originalOrderFirstName,
        originalOrderLastName: orderData.originalOrderLastName,
        originalOrderStreetName: orderData.originalOrderStreetName,
        originalOrderHouseNumber: orderData.originalOrderHouseNumber,
        originalOrderPlaceName: orderData.originalOrderPlaceName,
        originalOrderPONumber: orderData.originalOrderPONumber,
        originalOrderPhoneNumber: orderData.originalOrderPhoneNumber,
        originalOrderEmail: orderData.originalOrderEmail,
        originalOrderCOD: orderData.originalOrderCOD,
        originalOrderCountryName: orderData.originalOrderCountryName,
        originalOrderPaymentType: orderData.originalOrderPaymentType,
        originalOrderDeliveryCompany: orderData.originalOrderDeliveryCompany,
        originalOrderDeliveryCompanies:
          orderData.originalOrderDeliveryCompanies,
      });
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (mergeOrderChangeReason.length >= 15) {
        setCanSaveMergeData(true);
      } else {
        setCanSaveMergeData(false);
      }
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [mergeOrderChangeReason]);

  async function submitHandler(event){
    event.preventDefault();
    const data = new FormData(formRef.current);

    console.log("In action fn merge orders");
  
    const dataForMerge = {
      mergeOrderNumber: data.get("mergeOrderNumber"),
      mergeOrderFirstName: data.get("mergeOrderFirstName"),
      mergeOrderLastName: data.get("mergeOrderLastName"),
      mergeOrderStreetName: data.get("mergeOrderStreetName"),
      mergeOrderHouseNumber: data.get("mergeOrderHouseNumber"),
      mergeOrderPlaceName: data.get("mergeOrderPlaceName"),
      mergeOrderPONumber: data.get("mergeOrderPONumber"),
      mergeOrderPhoneNumber: data.get("mergeOrderPhoneNumber"),
      mergeOrderEmail: data.get("mergeOrderEmail"),
      mergeOrderCOD: data.get("mergeOrderCOD"),
  
      mergeOrderCodCheckBox: data.get("mergeOrderCodCheckBox"),
      mergeOrderCountryName: data.get("mergeOrderCountryName"),
      mergeOrderSelectedDeliveryCompany: JSON.parse(
        data.get("selectedDeliveryCompany")
      ),
      mergeOrderRemark: data.get("mergeOrderRemark"),
      mergeOrderChangeReason: data.get("mergeOrderChangeReason"),
    };
  
    const apiCallConfig = {
      method: "post",
      url: `${URL}/customerSupport/saveOrderMergeData`,
      headers: {
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json"
      },
      data: dataForMerge,
    }
  
    const message = await sendRequest(apiCallConfig);
    console.log("Response from saveOrderMergeData is: " + JSON.stringify(message));
  
    onSubmit(message);
  }

  return (
    <>
      <div className="jumbotron text-center">
        <h1>Spajanje narudžbi</h1>
      </div>

      <div className="d-flex-md">
        <form method="post" ref={formRef} onSubmit={submitHandler}>
          <div className="d-flex row">
            <div className="d-flex col-md-1"></div>

            <div className="d-flex col-md-3 justify-content-center">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label>Podaci jedne narudžbe</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        id="originalOrderNumber"
                        value={originalOrderNumber}
                        onChange={originalOrderChangeHandler}
                      ></input>
                      <button
                        onClick={addOrderHandler}
                        className="btn btn-primary my-2"
                      >
                        Dohvati podatke narudžbe
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Ime</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        value={originalOrderFirstName}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Prezime</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        value={originalOrderLastName}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Ulica</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        value={originalOrderStreetName}
                        readOnly
                      ></input>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label>Kućni broj</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        value={originalOrderHouseNumber}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Mjesto</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        value={originalOrderPlaceName}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Poštanski broj</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        value={originalOrderPONumber}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Telefon</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        value={originalOrderPhoneNumber}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Email</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        value={originalOrderEmail}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Pouzeće</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="number"
                        value={originalOrderCOD}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Država</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        value={originalOrderCountryName}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Način plaćanja</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        value={originalOrderPaymentType}
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Dostavna služba</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        value={
                          originalOrderDeliveryCompany.showDeliveryCompName
                        }
                        readOnly
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Napomena</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <textarea
                        className="form-control"
                        rows="4"
                        name="mergeOrderRemark"
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="d-flex col-md-1 justify-content-center align-items-center">
              <div className="d-block row">
                <button
                  id="buttonAddOrder"
                  onClick={mergeOrdersData}
                  className="btn btn-primary"
                >
                  Dodaj narudžbu
                </button>
              </div>
            </div>

            <div className="d-flex col-md-3 justify-content-center">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label>Spojene narudžbe</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="mergeOrderNumber"
                        value={mergeOrderNumber}
                        onChange={mergeOrderNumberChangeHandler}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Ime</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="mergeOrderFirstName"
                        value={mergeOrderFirstName}
                        onChange={mergeOrderFirstNameChangeHandler}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Prezime</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="mergeOrderLastName"
                        value={mergeOrderLastName}
                        onChange={mergeOrderLastNameChangeHandler}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Ulica</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="mergeOrderStreetName"
                        value={mergeOrderStreetName}
                        onChange={mergeOrderStreetNameChangeHandler}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Kućni broj</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="mergeOrderHouseNumber"
                        value={mergeOrderHouseNumber}
                        onChange={mergeOrderHouseNumberChangeHandler}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Mjesto</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="mergeOrderPlaceName"
                        value={mergeOrderPlaceName}
                        onChange={mergeOrderPlaceNameChangeHandler}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Poštanski broj</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="mergeOrderPONumber"
                        value={mergeOrderPONumber}
                        onChange={mergeOrderPONumberChangeHandler}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Telefon</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="mergeOrderPhoneNumber"
                        value={mergeOrderPhoneNumber}
                        onChange={mergeOrderPhoneNumberChangeHandler}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Email</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        name="mergeOrderEmail"
                        value={mergeOrderEmail}
                        onChange={mergeOrderEmailChangeHandler}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Pouzeće</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        disabled={!isMergeOrderCODEditable}
                        value={mergeOrderCOD}
                        onChange={mergeOrderCODChangeHandler}
                        name="mergeOrderCOD"
                      ></input>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="mergeOrderCodCheckBox"
                        onChange={codCheckBoxHandler}
                      ></input>
                      Izmjena pouzeća
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Država</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        id="mergeOrderCountryName"
                        value={mergeOrderCountryName}
                        onChange={mergeOrderCountryNameChangeHandler}
                        name="mergeOrderCountryName"
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Dostavna služba</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        className="form-select"
                        name="selectedDeliveryCompany"
                        value={JSON.stringify(selectedDeliveryCompany)}
                        onChange={deliveryCompanyChangeHandler}
                      >
                        {originalOrderDeliveryCompanies.map((comp, index) => (
                          <option
                            id={comp.showDeliveryCompName}
                            key={comp.showDeliveryCompName + "_" + index}
                            value={JSON.stringify(comp)}
                          >
                            {comp.showDeliveryCompName}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="d-flex col-md-2 justify-content-center">
              <div className="d-block ">
                <h5>Razlog spajanja narudžbe</h5>
                <textarea
                  className="form-control"
                  name="mergeOrderChangeReason"
                  value={mergeOrderChangeReason}
                  onChange={mergeReasonHandler}
                ></textarea>
                <button
                  className="btn btn-primary my-2"
                  disabled={!canSaveMergeData}
                  title="Potreban je unos od minimalno 15 znakova kao razlog spajanja narudžbe!"
                >
                  Spoji narudžbu
                </button>
              </div>
            </div>

            <div className="d-flex col-md-2"></div>
          </div>
        </form>
      </div>
    </>
  );
}

export default OrderMergeForm;
