function Dropdown({ title, value, handler, options }) {
  function selectedValueHandler(event) {
    handler(event.target.value);
  }

  return (
    <select className="form-control-sm p-2 mx-1 py my-1 mt-2" title={title} value={value} onChange={selectedValueHandler}>
      {options.map((o) => (
        <option id={o} key={o} value={o}>
          {o}
        </option>
      ))}
    </select>
  );
}

export default Dropdown;
