import { URL } from "../../../../api/orders";
import useApi from "../../../../hooks/use-api";
import { getAccessToken } from "../../../../util/auth";
import ModalWrapper from "./ModalWrapper";

function RemoveFromPrintConfirmation(props) {
  const { sendRequest, isLoading } = useApi();

  async function confirmButtonHandler() {
    const apiCallConfig = {
      url: `${URL}/customerSupport/removeOrderFromPrint`,
      headers: {
        Authorization: "Bearer " + getAccessToken(),
      },
      params: {
        orderNumber: props.orderNumber,
      },
    };

    const response = await sendRequest(apiCallConfig);

    let message = "Narudžba je uspješno maknuta iz tiska";
    if (response !== true) {
      message = "Dogodila se greška";
    }

    props.onConfirm(message);
  }

  return (
    <ModalWrapper onClose={props.onClose} title="Micanje iz tiska">
      <>
        {!isLoading && (
          <div className="modal-body">
            <h5 className="text-center">
              Jeste li sigurni da želite maknuti narudžbu iz tiska?
            </h5>
          </div>
        )}
        {isLoading && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <hr />
        <div className="modal-footer">
          {!isLoading && (
            <div className="d-flex justify-content-center">
              <button className="btn btn-primary mx-1" onClick={props.onClose}>
                Ne
              </button>
              <button
                className="btn btn-primary mx-1"
                onClick={confirmButtonHandler}
              >
                Da
              </button>
            </div>
          )}
        </div>
      </>
    </ModalWrapper>
  );
}

export default RemoveFromPrintConfirmation;
