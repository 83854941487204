import { useEffect, useState } from "react";
import { URL } from "../../../../api/orders";
import { getAccessToken } from "../../../../util/auth";
import ModalWrapper from "./ModalWrapper";
import useApi from "../../../../hooks/use-api";

function RemoveItem({
  itemCode,
  orderNumber,
  orderItemId,
  onClose,
  onConfirm,
}) {
  const [removalReason, setRemovalReason] = useState({
    removalReasonInput: "",
    removalReasonIsValid: false,
  });
  const {
    removalReasonInput: reasonInput,
    removalReasonIsValid: reasonIsValid,
  } = removalReason;
  const { sendRequest, isLoading } = useApi();

  useEffect(() => {
    const identifier = setTimeout(() => {
      if (reasonInput.length >= 15) {
        setRemovalReason((prevValue) => {
          return { ...prevValue, removalReasonIsValid: true };
        });
      } else {
        setRemovalReason((prevValue) => {
          return { ...prevValue, removalReasonIsValid: false };
        });
      }
    }, 150);

    return () => {
      clearTimeout(identifier);
    };
  }, [reasonInput, reasonIsValid]);

  function removalReasonChangeHandler(event) {
    setRemovalReason((prevValue) => {
      return { ...prevValue, removalReasonInput: event.target.value };
    });
  }

  async function confirmButtonHandler() {
    const apiCallConfig = {
      url: `${URL}/customerSupport/removeItem`,
      method: "post",
      headers: {
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
      },
      data: {
        orderNumber: orderNumber,
        itemCode: itemCode,
        removalReason: reasonInput,
        orderItemId: orderItemId,
      },
    };
    const message = await sendRequest(apiCallConfig);

    setRemovalReason({ removalReasonInput: "", removalReasonIsValid: false });

    onConfirm(JSON.stringify(message));
  }

  return (
    <ModalWrapper onClose={onClose} title="Micanje proizvoda">
      {!isLoading && (
        <>
          <div className="modal-body">
            <label>Broj narudžbe: </label>
            <input
              className="form-control"
              disabled
              value={orderNumber}
            ></input>
          </div>
          <div>
            <label>Razlog micanja proizvoda:</label>
            <textarea
              className="form-control"
              onChange={removalReasonChangeHandler}
              value={reasonInput}
              rows={3}
            ></textarea>
          </div>
        </>
      )}
      {isLoading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <hr />
      <div className="modal-footer">
        {!isLoading && (
          <>
            {
              <button className="btn btn-primary my-2 mx-2" onClick={onClose}>
                Close
              </button>
            }
            {
              <button
                className="btn btn-primary my-2"
                disabled={!reasonIsValid}
                onClick={confirmButtonHandler}
              >
                Ukloni proizvod
              </button>
            }
          </>
        )}
      </div>
    </ModalWrapper>
  );
}

export default RemoveItem;
