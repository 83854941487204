import { useEffect, useState } from "react";
import ModalWrapper from "./ModalWrapper";
import useApi from "../../../../hooks/use-api";
import { getAccessToken } from "../../../../util/auth";
import { URL } from "../../../../api/orders";

function CancelOrderConfirmation(props) {
  const { sendRequest, isLoading } = useApi();
  const [cancelReason, setCancelReason] = useState("");
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setConfirmButtonDisabled(cancelReason.trim().length < 15);
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [cancelReason]);

  function cancelReasonHandler(event) {
    setCancelReason(event.target.value);
  }

  async function confirmButtonHandler() {
    const apiCallConfig = {
      url: `${URL}/customerSupport/cancelOrder`,
      headers: {
        Authorization: "Bearer " + getAccessToken(),
      },
      params: {
        orderNumber: props.orderNumber,
        cancelReason: cancelReason,
      },
    };
    const response = await sendRequest(apiCallConfig);

    let message = "Narudžba je uspješno otkazana";
    if (response !== true) {
      message = "Dogodila se greška prilikom otkazivanja";
    }

    props.onConfirm(message);
  }

  return (
    <ModalWrapper onClose={props.onClose} title="Otkazivanje narudžbe">
      <div className="modal-body">
        {!isLoading && (
          <>
            <h5>Unesite razlog otkazivanja:</h5>
            <textarea
              value={cancelReason}
              onChange={cancelReasonHandler}
              className="form-control p-2 py my-2 mt-1"
              rows={3}
            ></textarea>
          </>
        )}
        {isLoading && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <hr />
      </div>
      <div className="modal-footer">
        {!isLoading && 
          <>
            <button className="btn btn-primary mx-1" onClick={props.onClose}>
              Cancel
            </button>
            <button
              className="btn btn-primary mx-1"
              disabled={confirmButtonDisabled}
              onClick={confirmButtonHandler}
            >
              Otkaži
            </button>
          </>
        }
      </div>
    </ModalWrapper>
  );
}

export default CancelOrderConfirmation;
