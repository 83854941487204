import { useState } from "react";
import OrderActionBar from "./OrderActionBar";
import OrderInfo from "./OrderInfo";
import OrderItems from "./OrderItems";
import RemoveFromPrintConfirmation from "./modals/RemoveFromPrintConfirmation";
import CancelOrderConfirmation from "./modals/CancelOrderConfirmation";
import ResponseModal from "./modals/ResponseModal";

function Order({ order }) {
  const [removeFromPrintIsShown, setRemoveFromPrintIsShown] = useState(false);
  const [cancelOrderIsShown, setCancelorderIsShown] = useState(false);
  const [responseModalIsShown, setResponseModalIsShow] = useState(false);
  const [responseModalMessage, setResponseModalMessage] = useState("");

  function showRemoveFromPrintModalHandler() {
    document.body.style.overflow = 'hidden';
    setRemoveFromPrintIsShown(true);
  }
  function hideRemoveFromPrintModalHandler() {
    document.body.style.overflow = 'auto';
    setRemoveFromPrintIsShown(false);
  }

  function showCancelOrderModalHandler() {
    document.body.style.overflow = 'hidden';
    setCancelorderIsShown(true);
  }
  function hideCancelOrderModalHandler() {
    document.body.style.overflow = 'auto';
    setCancelorderIsShown(false);
  }

  function confirmRemoveFromPrintModalHandler(message) {
    setResponseModalMessage(message);
    setRemoveFromPrintIsShown(false);
    setResponseModalIsShow(true);
  }

  function hideResponseModalHandler() {
    document.body.style.overflow = 'auto';
    setResponseModalIsShow(false);

    window.location.reload();
  }

  function confirmCancelOrderModalHandler(message) {
    setResponseModalMessage(message);
    setCancelorderIsShown(false);
    setResponseModalIsShow(true);
  }

  const evaluationOfDeliveryTime = (
    <strong>{`${order.evaluation1} ${order.evaluation2}`}</strong>
  );

  return (
    <div className="container-flex container-md">
      {removeFromPrintIsShown && (
        <RemoveFromPrintConfirmation
          orderNumber={order.orderNumber}
          onClose={hideRemoveFromPrintModalHandler}
          onConfirm={confirmRemoveFromPrintModalHandler}
        />
      )}

      {cancelOrderIsShown && (
        <CancelOrderConfirmation
          orderNumber={order.orderNumber}
          onClose={hideCancelOrderModalHandler}
          onConfirm={confirmCancelOrderModalHandler}
        />
      )}

      {responseModalIsShown && (
        <ResponseModal
          onClose={hideResponseModalHandler}
          message={responseModalMessage}
        />
      )}

      <OrderActionBar
        order={order}
        onShowRemoveFromPrintConfirmation={showRemoveFromPrintModalHandler}
        onShowCancelOrderConfirmation={showCancelOrderModalHandler}
      />

      <div className="border text-center my-2">{evaluationOfDeliveryTime}</div>

      <OrderItems order={order} />
      <OrderInfo order={order} />
    </div>
  );
}

export default Order;
