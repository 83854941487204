import { useNavigate } from "react-router-dom";
import PageContent from "../components/PageContent";
import { useEffect } from "react";
import { isTokenValid } from "../util/auth";

function HomePage() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isTokenValid()) {
      return navigate("/auth");
    }
  }, [navigate]);

  return (
    <PageContent title="Dobrodošli!">
      <div className="h-100 d-flex justify-content-center">
        <img
        className="img-fluid"
          src="/img/CustomerSupportImage.png"
          alt="Tvornica snova logo"
          // width={500}
        ></img>
      </div>
    </PageContent>
  );
}

export default HomePage;
