import { useState } from "react";
import { NavLink, useRouteLoaderData } from "react-router-dom";

function CustomerSupportNavigation() {
  const token = useRouteLoaderData("root");

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const navLinkClickHandler = () => {
    if (isNavbarOpen) {
      setIsNavbarOpen(false);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary d-flex justify-content-center m-1">
      <div className="d-flex justify-content-center">
        <div className="container-fluid ">
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
            aria-expanded="false"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`}
          >
            <ul className="navbar-nav ml-auto">
              {token && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    onClick={navLinkClickHandler}
                    to="/customerSupport/orders"
                    end
                  >
                    Pretraživanje narudžbi
                  </NavLink>
                </li>
              )}
              {token && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    onClick={navLinkClickHandler}
                    to="/customerSupport/couponCheck"
                    end
                  >
                    Provjera kupona
                  </NavLink>
                </li>
              )}
              {token && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    onClick={navLinkClickHandler}
                    to="/customerSupport/bookSearch"
                    end
                  >
                    Pretraživanje knjiga
                  </NavLink>
                </li>
              )}
              {token && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    onClick={navLinkClickHandler}
                    to="/customerSupport/changedOrders"
                    end
                  >
                    Promijenjene narudžbe
                  </NavLink>
                </li>
              )}
              {token && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    onClick={navLinkClickHandler}
                    to="/customerSupport/mergedOrders"
                    end
                  >
                    Spojene narudžbe
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default CustomerSupportNavigation;
