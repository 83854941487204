import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AuthenticationPage, {
  action as authAction,
} from "./pages/Authentication";
import CouponCheckPage, {
  action as couponCheckAction,
} from "./pages/CouponCheck";
import CustomerSupportHomePage from "./pages/CustomerSupportHome";
import CustomerSupportRootLayout from "./pages/CustomerSupportRoot";
import ErrorPage from "./pages/Error";
import HomePage from "./pages/Home";
import OrderDetailPage, {
  loader as orderDetailLoader,
} from "./pages/OrderDetail";
import BookSearchPage from "./pages/BookSearch";
import OrderEditPage, { loader as orderEditLoader } from "./pages/OrderEdit";
import OrderMergePage, { loader as orderMergeLoader } from "./pages/OrderMerge";
import OrderViewBookDataPage, {
  loader as orderViewBookDataLoader,
} from "./pages/OrderViewBookData";
import RootLayout from "./pages/Root";
import { accessTokenLoader } from "./util/auth";
import NotFoundPage from "./pages/NotFound";
import MergedOrdersPage from "./pages/MergedOrders";
import ChangedOrdersPage from "./pages/ChangedOrders";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    id: "root",
    loader: accessTokenLoader,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "customerSupport",
        element: <CustomerSupportRootLayout />,
        children: [
          {
            path: "orders",
            element: <CustomerSupportHomePage />,
          },
          {
            path: "editOrderData/:orderNumber",
            loader: orderEditLoader,
            element: <OrderEditPage />,
          },
          {
            path: "mergeOrderData/:orderNumber",
            element: <OrderMergePage />,
            loader: orderMergeLoader,
          },
          {
            path: "couponCheck",
            element: <CouponCheckPage />,
            action: couponCheckAction,
          },
          {
            path: "bookSearch",
            element: <BookSearchPage />,
          },
          {
            path: "changedOrders",
            element: <ChangedOrdersPage />,
          },
          {
            path: "mergedOrders",
            element: <MergedOrdersPage />,
          },
          {
            path: "order/:orderNumber",
            element: <OrderDetailPage />,
            loader: orderDetailLoader,
          },
        ],
      },
      {
        path: "/customerSupportApp/mergeOrderData?orderNumer=:orderNumber",
        element: <OrderMergePage />,
        loader: orderMergeLoader,
      },
    ],
  },
  {
    path: "/auth",
    element: <AuthenticationPage />,
    // errorElement: <ErrorPage />,
    action: authAction,
  },
  {
    path: "/customerSupportApp/bookData/:bookCode",
    element: <OrderViewBookDataPage />,
    loader: orderViewBookDataLoader,
    errorElement: <ErrorPage />
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
