function Coupon({coupon }) {
  return (
    <>
      <td>{coupon.order_code}</td>
      <td>{coupon.coupon}</td>
      <td>{coupon.locked}</td>
      <td>{coupon.orderId}</td>
    </>
  );
}

export default Coupon;
