import { useEffect, useState } from "react";
import BookDataItems from "../components/CustomerSupport/bookSearch/BookDataItems";
import BookSearchForm from "../components/CustomerSupport/bookSearch/BookSearchForm";
import { getAccessToken} from "../util/auth";
import useApi from "../hooks/use-api";
import { URL } from "../api/orders";
import { useQuery } from "@tanstack/react-query";

function BookSearchPage() {
  const [initData, setInitData] = useState();
  const { sendRequest, isLoading } = useApi();
  const [bookSearchFormData, setBookSearchFormData] = useState();

  useEffect(() => {
    const getData = async () => {
      const apiCallConfig = {
        url: `${URL}/customerSupport/searchBooksOptionsInitData`,
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
      };
      const initialData = await sendRequest(apiCallConfig);

      setInitData(initialData);
    };

    getData();
  }, [sendRequest]);

  function formSubmitHandler(data) {
    setBookSearchFormData(data);
  }

  const {
    data: bookData,
    refetch: refetchBookData,
    isFetched: isFetchedBookData,
  } = useQuery({
    queryKey: ["bookData"],
    queryFn: async () => {
      const apiCallConfig = {
        url: `${URL}/customerSupport/getBookSearchData`,
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
        params: bookSearchFormData,
      };
      const response = await sendRequest(apiCallConfig);
      return response.data;
    },
    enabled: false,
  });

  useEffect(() => {
    if(bookSearchFormData){
      refetchBookData();
    }
  }, [refetchBookData,bookSearchFormData])

  return (
    <div className="d-md-flex flex-column container-fluid col-sm-6 justify-content-center">
      {initData?.options && (
        <BookSearchForm
          options={initData.options}
          onSubmit={formSubmitHandler}
        ></BookSearchForm>
      )}
      {!isLoading && isFetchedBookData && bookData?.apiError && (
        <p>{bookData.apiError.message}</p>
      )}
      {isLoading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {!isLoading &&
        isFetchedBookData &&
        bookData &&
        !bookData.apiError && <BookDataItems items={bookData} />}
    </div>
  );
}
export default BookSearchPage;
