import React, { useEffect, useState } from "react";
import useApi from "../hooks/use-api";
import { useQuery } from "@tanstack/react-query";
import { getAccessToken } from "../util/auth";
import { URL } from "../api/orders";
import MergedOrdersTable from "../components/CustomerSupport/orders/MergedOrdersTable";
import ResponseModal from "../components/CustomerSupport/orders/modals/ResponseModal";

function MergedOrdersPage() {
  const { sendRequest, isLoading } = useApi();
  const [orders, setOrders] = useState();
  const [isOrderSelected, setIsOrderSelected] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseData, setResponseData] = useState();

  const { data, isFetching, isFetched, refetch } = useQuery({
    queryKey: ["mergedOrders"],
    queryFn: async () => {
      const apiCallConfig = {
        url: `${URL}/customerSupport/mergedOrders`,
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
      };
      const response = await sendRequest(apiCallConfig);
      setOrders(response);

      return response;
    },
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  function showAllMergedDataHandler() {
    setOrders(data);
  }

  function showMergedDataInProgressHandler() {
    setOrders((prevValue) => {
      return prevValue.filter((s) => {
        return !s.changed_data;
      });
    });
  }

  function handleRowClick(order) {
    setSelectedOrders((prevOrders) => {
      if (prevOrders.length !== 0 && prevOrders.includes(order)) {
        const newOrders = prevOrders.filter((s) => s !== order);
        if (newOrders.length === 0) {
          setIsOrderSelected(false);
        }
        return newOrders;
      } else {
        if (!order.changedData) {
          if (prevOrders.length === 0) {
            setIsOrderSelected(true);
          }

          return [...prevOrders, order];
        } else {
          return prevOrders;
        }
      }
    });

  }

    async function deleteChangeHandler() {
    const apiCallConfig = {
      url: `${URL}/customerSupport/removeFromMergedOrders`,
      method: "post",
      data: selectedOrders,
      headers: {
        Authorization: "Bearer " + getAccessToken(),
        "Content-Type": "application/json",
      },
    };

    const response = await sendRequest(apiCallConfig);

    if (response.apiError) {
      setResponseData(response.apiError.message);
      setShowResponseModal(true);
    } else {
      setResponseData(response);
      setShowResponseModal(true);
    }
  }

  function closeResponseModalHandler() {
    setShowResponseModal(false);

    window.location.reload();
  }

  return (
    <>
      {showResponseModal && (
        <ResponseModal message={responseData} onClose={closeResponseModalHandler} />
      )}
      {(isFetching || isLoading ) && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {!isFetching && !isLoading && isFetched && data && !data.apiError && (
        <div className="d-flex justify-content-center">
          <div className="row">
            <div className="col-md-4 text-center">
              <button
                className="btn btn-primary mx-1 my-1"
                onClick={showAllMergedDataHandler}
              >
                Prikaži sve promjene
              </button>
            </div>

            <div className="col-md-4 text-center">
              <button
                className="btn btn-success mx-1 my-1"
                onClick={showMergedDataInProgressHandler}
              >
                Prikaži promjene u tijeku
              </button>
            </div>

            <div className="col-md-4 text-center">
              <button
                className="btn btn-danger mx-1 my-1"
                onClick={deleteChangeHandler}
                disabled={!isOrderSelected}
              >
                Obriši promjenu
              </button>
            </div>
          </div>
        </div>
      )}
      {!isFetching && !isLoading && isFetched && !data.apiError && orders && (
        <MergedOrdersTable orders={orders}
        selectedOrders={selectedOrders}
        handleRowClick={handleRowClick} />
      )}
    </>
  );
}

export default MergedOrdersPage;
